import React from 'react';
import { SearchInput } from '../../../components/search-input/search-input';
import './DefaultPageHeader.scss';
import { PassportNonce } from '../../../lib/constants';
import SafeHTMLRender from '../SafeHTMLRender';

export interface IDefaultPageHeaderProps {
  label: string;
  handleSearch?: (value: string) => void;
  notes?: string;
  dataTestId?: string;
}

const withSearch = 'default-page-title__with-search overflow-hidden break-all';

const DefaultPageHeader: React.FC<IDefaultPageHeaderProps> = (props: IDefaultPageHeaderProps): JSX.Element => {
  const { label, handleSearch, notes } = props;

  return (
    <>
      <div className='default-page-header'>
        <h1
          className={`default-page-title font-size-40px ${handleSearch ? withSearch : ''}`}
        >
          {label}
        </h1>
        {handleSearch && (
          <SearchInput
            onChange={handleSearch}
            withDebounce
            withResetSearchButton
          />
        )}
      </div>
      
      {notes && <SafeHTMLRender nonce={PassportNonce.RICH_TEXT} html={notes || ''} className='pb-8 px-8 word-break'/>}
      <hr/>
    </>
  );
};

export default DefaultPageHeader;
