import React, { useLayoutEffect, useRef, useState } from 'react';
import useConnectMeetingDisabled from '../../../hooks/use-connect-meeting-disabled';
import { PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { HOSTED_BY, PassportNonce, REPLAYS_CONTENT_HIGHT_BEFORE_EXPAND } from '../../../lib/constants';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import { DateFnsFormats, dateToFormattedString, getIsEnUsRegion, getTimeInLocalFormat } from '../../../lib/helpers/dateHelper';
import MeetingDetailsLinksMapper from '../../shared/MeetingDetailsLinks/MeetingDetailsLinksMapper';
import { MoreButton } from '../../shared/MoreButton';
import { WatchButton } from '../../shared/WatchButton';
import { MeetingOrganizationsList } from '../SessionWrapper/MeetingOrganizationsList/MeetingOrganizationsList';
import { MeetingTopic } from '../SessionWrapper/MeetingTopic';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import { PassportPageType, ReplaysPageType } from '../../../lib/api/custom-page.types';
import CardButtons from './CardButtons/CardButtons';
import SafeHTMLRender from '../../shared/SafeHTMLRender';

export interface IReplaysMeetingTileProps {
  event: EventType;
  meeting: PrivateMeetingSessionDetailsResponseType;
  timeZone: string;
  isUpcomingSession?: boolean;
  isClosestMeeting?: boolean;
  isCarousel?: boolean;
  transformedTimeZone?: string;
}

const ReplaysMeetingTile = (props: IReplaysMeetingTileProps): JSX.Element => {
  const { event, meeting, timeZone, isUpcomingSession = false, transformedTimeZone } = props;

  const {
    thumbnailImageUrl,
    presenterOrganizations,
    hostOrganizations,
    topic,
    featured,
    startDateTime,
    links = [],
    notes,
  } = meeting;

  const [contentExpanded, setContentExpanded] = useState(false);
  const [toggleVisible, setToggleVisible] = useState(false);

  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;

  const eventSettings = useEventSettings() as EventSettingsContextType;
  const isConnectBtnDisabled = useConnectMeetingDisabled(eventSettings, startDateTime);

  const fullDate = dateToFormattedString(
    startDateTime,
    getIsEnUsRegion()
      ? DateFnsFormats.StringifiedUSShortDay
      : DateFnsFormats.StringifiedEUShortDay, timeZone);

  let formattedHostStartTime = null;

  if (isUpcomingSession) {
    formattedHostStartTime = getTimeInLocalFormat(startDateTime, timeZone);
  }

  const expandableContentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore*/
    const expandableScrollHeight = expandableContentRef.current?.scrollHeight ?? 0;
    if (expandableScrollHeight > REPLAYS_CONTENT_HIGHT_BEFORE_EXPAND) {
      setContentExpanded(false);
      setToggleVisible(true);
    }
  }, []);

  const pageConfiguration = event.customPages.find(el => el.type === PassportPageType.REPLAYS) as ReplaysPageType;
  const preRegistrationEnabled = pageConfiguration.replaysPreRegistrationEnabled;
  const contentDateStyles = transformedTimeZone ? 'block' : 'flex';
  const contentDayStyles = transformedTimeZone ? 'mr-1' : '';
  const startTimeStyles = transformedTimeZone ? 'ml-0' : '';
  const upcomingSessionClassName = isUpcomingSession && !contentExpanded ? 'meeting-card--upcoming' : '';
  const contentExpandedClassName = contentExpanded ? ' meeting-card--expanded' : '';
  const thumbnailImageUrlClassName = !thumbnailImageUrl ? ' meeting-card__image--empty' : '';

  return (
    <div
      className={`meeting-card ${upcomingSessionClassName}${contentExpandedClassName}${thumbnailImageUrlClassName}`}
      data-meeting-id={meeting.meetingId}
    >
      <div className='meeting-card__content'>
        <div
          ref={expandableContentRef}
          className='meeting-card__expandable-content meeting-card__content-inner'
        >
          {thumbnailImageUrl &&
            <div className='meeting-card__img'>
              <img
                src={thumbnailImageUrl}
                alt='thumbnailImageUrl'
                title='thumbnailImageUrl'
              />
            </div>
          }
          <div
            className={`${contentDateStyles} meeting-card__content-date`}
          >
            <span title={fullDate} className={`${contentDayStyles} meeting-card__content-day`}>
              {fullDate}
            </span>
            <span
              className={`mr-1 start-time text-left leading-none ${startTimeStyles}`}
            >
              {formattedHostStartTime}
            </span>
            <span className={`${startTimeStyles} start-time normal-case leading-none word-break`}>{transformedTimeZone}</span>
          </div>
          <MeetingTopic
            orgs={presenterOrganizations ?? []}
            topic={topic}
            isFeatured={featured}
            classNames='meeting-card__content-topic'
          />
          <MeetingOrganizationsList
            orgs={presenterOrganizations ?? []}
            organizationClassName='meeting-card__organizations-list-item'
            wrapperClassName='meeting-card__organizations-list'
          />
          <MeetingOrganizationsList
            organizationHeader={HOSTED_BY}
            orgs={hostOrganizations ?? []}
            organizationClassName='meeting-card__organizations-list-item'
            wrapperClassName='meeting-card__organizations-list'
          />
          {links.length > 0 && <MeetingDetailsLinksMapper links={links} className='meeting-card__links-mapper' dataTestId='meeting-card-links-mapper' />}
          {notes && <SafeHTMLRender nonce={PassportNonce.RICH_TEXT} html={notes} className='flex flex-col notes word-break overflow-hidden'/>}
        </div>
        <div className='meeting-card__content-footer-buttons'>
          {toggleVisible &&
            <div className='meeting-card__content-inner meeting-card__content-inner-more-button'>
              <MoreButton
                action={() => setContentExpanded(prev => !prev)}
                isContentExpanded={contentExpanded}
                ariaLabel='toggle content'
                className='meeting-card__content-more-button'
              />
            </div>
          }
          {isAuthenticated &&
            (isUpcomingSession ?
              <CardButtons
                event={event}
                meeting={meeting}
                preRegistrationEnabled={preRegistrationEnabled}
                isFeatured={false}
              />
              :
              <div className='meeting-card__content-inner'>
                <WatchButton
                  isDisabled={isConnectBtnDisabled}
                  meetingDetails={meeting}
                  className='meeting-card__watch-btn'
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ReplaysMeetingTile;
