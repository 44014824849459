import React from 'react';
import { PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import { IReplaysContext, TReplaysListResponse, useReplaysContext } from './ReplaysContextProvider';
import ReplaysFeatureMeetingTile from './ReplaysFeatureMeetingTile';
import Button from '../../../components/button';
import { UpcomingSessionsTypeOptions } from '../../../lib/api/custom-page.types';
import { Carousel } from '../SessionWrapper/CarouselLayout/Carousel';
import ReplaysMeetingTile from './ReplaysMeetingTile';

interface IProps {
  event: EventType;
  showAnchorElement: boolean;
  sessionLabel: string;
  pastSessionsLabel: string;
  upcomingSessions: TReplaysListResponse;
  transformedTimeZone: string;
}

const ReplaysUpcomingSessionsWrapper = (props: IProps): JSX.Element | null => {
  const {
    event,
    showAnchorElement,
    sessionLabel,
    pastSessionsLabel = 'Past Sessions',
    upcomingSessions,
    transformedTimeZone,
  } = props;

  const { timeZone } = event;

  const { pageConfiguration, isValidatingUpcomingSessions, pastSessionsRef, upcomingSessionsRef, scrollToRef } = useReplaysContext() as IReplaysContext;

  if (upcomingSessions && !!upcomingSessions?.length && !isValidatingUpcomingSessions) {
    const currentDate = new Date();

    const closestDifference: number = Math.min(
      ...upcomingSessions.map(meeting => Math.abs(currentDate.getTime() - new Date(meeting.endDateTime).getTime()))
    );

    const closestSessions: PrivateMeetingSessionDetailsResponseType[] = upcomingSessions.filter(
      meeting => Math.abs(currentDate.getTime() - new Date(meeting.endDateTime).getTime()) === closestDifference
    );

    const restUpcomingSessions = upcomingSessions.filter(meeting => !closestSessions.includes(meeting));

    const screenBreakpoints = {
      small: 600,
      medium: 768,
      large: 1280,
    };

    const countSliders = {
      mobLimit: 1,
      tableLimit: 1,
      laptopLimit: 3
    };

    const responsive = {
      [screenBreakpoints.small]: { items: 1 },
      [screenBreakpoints.medium]: { items: 1 },
      [screenBreakpoints.large]: { items: 3 },
    };

    return (
      <div className='px-8'>
        <div>
          <div className='flex justify-between  items-center'>
            <h1 className='section-label' ref={upcomingSessionsRef} data-test-id='replays-upcoming-sessions-label'>{sessionLabel || 'Upcoming Sessions'}</h1>
            {showAnchorElement &&
              <Button.Common
                name='watch'
                onClick={() => scrollToRef(pastSessionsRef)}
                label={`View ${pastSessionsLabel}`}
                className='replays-anchor'
                data-test-id='view-past-sessions'
                ariaLabel={`View ${pastSessionsLabel}`}
              />
            }
          </div>
          <div className='flex flex-wrap'>
            {
              closestSessions.map(meeting =>
                <ReplaysFeatureMeetingTile
                  key={meeting.meetingId}
                  meeting={meeting}
                  timeZone={timeZone}
                  event={props.event as EventType}
                  isUpcomingSession
                  isClosestMeeting
                  transformedTimeZone={transformedTimeZone}
                />
              )
            }
          </div>
          {pageConfiguration.upcomingSessionType === UpcomingSessionsTypeOptions.AllUpcomingSessions &&
            <div className='replays-carousel-wrapper'>
              <Carousel
                responsiveSettings={responsive}
                countSliders={countSliders}
                screenBreakpoints={screenBreakpoints}
                isGeneralSessions={false}
              >
                {restUpcomingSessions.map((meeting, i) => (
                  <ReplaysMeetingTile
                    key={meeting.meetingId}
                    event={props.event as EventType}
                    meeting={meeting}
                    timeZone={timeZone}
                    isUpcomingSession
                    isCarousel
                    transformedTimeZone={transformedTimeZone}
                  />
                ))}
              </Carousel>
            </div>
          }
        </div>
      </div>
    );
  }
  return null;
};

export default ReplaysUpcomingSessionsWrapper;
