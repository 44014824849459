import React, { RefObject, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/_base/Button';
import Buttons from '../../../../components/button';
import { JOIN_STATUS } from '../../../../lib/constants';
import Icon from '../../../../components/_base/Icon';
import useMeetingCalendar from '../../../../hooks/use-meeting-calendar';
import useEventToken from '../../../../hooks/use-event-token';
import { usePathPrefix } from '../../../../hooks/use-path-prefix';
import { normalizeRoutePath } from '../../../../lib/helpers/urlHelper';

export const JoinButton = (props: { eventId: string, meetingId: string }): JSX.Element | null => {
  const { eventId, meetingId } = props;
  const history = useHistory();
  const pathPrefix = usePathPrefix();

  return (
    <Button
      name='joinButton'
      type='button'
      onClick={() => history.push(normalizeRoutePath(`/session/${eventId}/meeting/${meetingId}`, pathPrefix), history.location.pathname)}
      label='Join'
      className='join-button'
      ariaLabel='Join Meeting'
      data-test-id='replays-join-button'
    />
  );
};

export const ViewDetailsButton = (props: { eventId: string, meetingId: string}): JSX.Element | null => {
  const { eventId, meetingId } = props;
  const history = useHistory();
  const pathPrefix = usePathPrefix();

  return (
    <Button
      name='viewDetailsButton'
      type='button'
      onClick={() => history.push(normalizeRoutePath(`/session/${eventId}/meeting/${meetingId}`, pathPrefix), history.location.pathname)}
      label='View Details'
      className='view-details-button'
      ariaLabel='View Details'
      data-test-id='replays-view-details-button'
    />
  );
};

export const RegisterButton = (props: { joinStatus: JOIN_STATUS, toggleStatusOnCard: () => void }): JSX.Element | null => {
  const { joinStatus, toggleStatusOnCard } = props;

  if (joinStatus === JOIN_STATUS.NOT_JOINED) {
    return (
      <div
        className='base-register register-button'
        data-test-id='replays-register-button'
        onClick={() => toggleStatusOnCard()}
        role='button'
        aria-label='Register'
      >
        <div>
          <Icon.Add className='mr-1 align-top' viewBox='0 0 24 24' />
        </div>
        <div>Register</div>
      </div>
    );
  } else if (joinStatus === JOIN_STATUS.JOINED_AS_REGULAR) {
    return (
      <div
        className='base-register registered-button base-register__disabled'
        data-test-id='replays-registered-button'
        onClick={() => toggleStatusOnCard()}
        role='button'
        aria-label='Registered'
      >
        <div>
          <Icon.Added className='mr-1 fill-off-black align-top' viewBox='0 0 24 24' />
        </div>
        <div>Registered</div>
      </div>
    );
  } else if (joinStatus === JOIN_STATUS.JOINED_AS_REGISTRANT) {
    return (
      <div className='register-cancel-buttons-block' >
        <div
          className='base-register registered-button'
          data-test-id='replays-registered-button'
          onClick={() => toggleStatusOnCard()}
        >
          <div>
            <Icon.Added className='mr-1 fill-off-black align-top' viewBox='0 0 24 24' />
          </div>
          <div>Registered</div>
        </div>

        <div
          className='base-register cancel-button'
          data-test-id='replays-cancel-button'
          onClick={() => toggleStatusOnCard()}
          role='button'
          aria-label='Cancel'
        >
          <div>
            <Icon.Remove className='mr-1 fill-white align-top' viewBox='1 1 18 18' height='14' width='14' />
          </div>
          <div>Cancel</div>
        </div>
      </div>
    );
  }
  return null;
};

export const AddToCalendarButton = (
  props: {
    eventId: number,
    name: string,
    isFeatured: boolean,
    meetingId: string,
    eventTitleForPassport: string
  }
): JSX.Element => {
  const { eventId, name, isFeatured, meetingId, eventTitleForPassport } = props;

  const { fetchOutlookCalendarForMeeting, fetchGoogleCalendarForMeeting } = useMeetingCalendar();
  const eventToken = useEventToken(eventId);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [customStyles, setCustomStyles] = useState<React.CSSProperties>({});

  const eventIdString = eventId.toString();
  const eventName = name || '';

  const onShowDropDown = (buttonRef: RefObject<HTMLDivElement>) => {
    const buttonLeftPosition = buttonRef.current?.getBoundingClientRect().x || 0;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (buttonLeftPosition > document.body.clientWidth / 2) {
      setCustomStyles({ left: '-214px' });
    } else {
      setCustomStyles({});
    }
  };

  return (
    <Buttons.OptionsButton
      customStyles={!isFeatured ? customStyles : {}}
      onShowDropDown={onShowDropDown}
      label='+ Add to Calendar'
      name='addToCalendar'
      disabled={false}
      eventId={eventIdString}
      eventName={eventName}
      className='select-box--button-small'
      downloadIcsFile={
        () => fetchOutlookCalendarForMeeting(
          eventIdString,
          meetingId,
          `${eventTitleForPassport || eventName}.ics`,
          eventToken
        )
      }
      openGoogleCalendar={() => fetchGoogleCalendarForMeeting(eventIdString, meetingId, eventToken)}
      iconButton
    />
  );
};