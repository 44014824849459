import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteProps, Redirect, useLocation } from 'react-router-dom';
import { HTTP_STATUS } from '../../config/api';
import { CALENDAR_SERVICE_PATH, NOT_FOUND_PATH } from '../../config/routes/paths';
import { IAuthContext, useAuth } from '../../lib/context-providers/auth-context';
import LoginWrapper from "./LoginWrapper/LoginWrapper";
import { useEventPublicInfo } from '../../hooks/api/public/use-event-public-details';
import { LoadingPage } from './LoadingPage';

export const PassportPlaceholder: React.FC<RouteProps> = (props: RouteProps): JSX.Element | null => {
  const { isValidating, error, data } = useEventPublicInfo();
  const auth = useAuth() as IAuthContext;

  const history = useHistory();
  const { state, search } = useLocation<{ from?: string }>();
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  useEffect(() => {
    if (state?.from && auth?.isAuthenticated) {
      state.from.endsWith(CALENDAR_SERVICE_PATH) ? history.replace(state.from + search) : history.replace(state.from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, auth?.isAuthenticated]);

  const handleSignIn = async (email: string, code: string) => {
    const isAuthenticated = await auth.signIn(email, code);
    if (state?.from && isAuthenticated) {
      history.replace(state.from);
    }
  };

  if (token) {
    return <LoadingPage token={token} />;
  }

  if (error && error.status === HTTP_STATUS.NOT_FOUND) return <Redirect to={NOT_FOUND_PATH} />;
  if (!isValidating && data && auth.isAuthenticated === false) {
    return (
      <LoginWrapper
        data={data}
        signIn={(email, code) => handleSignIn(email, code)}
      />
    );
  }

  return null;
};