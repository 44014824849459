import React from 'react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import { ROOT } from '../../config/routes/paths';
import { IEventCustomSettings, useCustomSettings } from '../../hooks/use-custom-settings';
import { useEventPublicInfo } from '../../hooks/api/public/use-event-public-details';
import RegistrationWrapper from './RegistrationWrapper/RegistrationWrapper';
import { IAuthContext, useAuth } from "../../lib/context-providers/auth-context";
import { useHistory } from "react-router-dom";
import { LoadingPage } from './LoadingPage';
import { usePathPrefix } from '../../hooks/use-path-prefix';
import { normalizeRoutePath } from '../../lib/helpers/urlHelper';

export const RegistrationPage: React.FC<RouteProps> = (props: RouteProps): JSX.Element | null => {
  const { registrationEnabled } = useCustomSettings() as IEventCustomSettings;
  const { data } = useEventPublicInfo();
  const auth = useAuth() as IAuthContext;
  const pathPrefix = usePathPrefix();

  const history = useHistory();
  const { state, search } = useLocation<{ from?: string, errorMsg?: string; email?: string }>();
  const searchParams = new URLSearchParams(search);
  const selectedTriageAnswer = searchParams.get('formName');
  const token = searchParams.get('token');

  const handleSignIn = async (email: string, code: string) => {
    const isAuthenticated = await auth.signIn(email, code);
    if (state?.from && isAuthenticated) {
      history.replace(state.from);
    }
  };

  if (token) {
    return <LoadingPage token={token}/>;
  }

  if (data && registrationEnabled) return (
    <RegistrationWrapper
      data={data}
      signIn={(email, code) => handleSignIn(email, code)}
      location={state}
      selectedTriageAnswer={selectedTriageAnswer || ''}
    />
  );
  if (registrationEnabled === false) return <Redirect to={normalizeRoutePath(ROOT, pathPrefix)} />;

  return null;
};

