import { ResponseError } from '../../../config/api';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import useSubdomain from '../../use-subdomain';
import API, { PROTECTED, EVENT, EventPrivateDetailsResponseType, AccountInfoResponseType } from '../../../lib/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import { getFirstPathSegmentFromLocation } from '../../../lib/helpers/urlHelper';
import { AccountContextType, useAccountContext } from '../../../lib/context-providers/account-context';

const { fetchEventPrivateDetails } = API[PROTECTED];

export function useEventPrivateInfo(): responseInterface<EventPrivateDetailsResponseType, ResponseError> {
  const subdomain = useSubdomain();
  const path = getFirstPathSegmentFromLocation();

  const auth = useAuth() as IAuthContext;
  const { accountDetails, isAccountSubdomain } = useAccountContext() as AccountContextType;

  const keyFn = () => {
    if (!auth.isAuthenticated) return null;
    if (isAccountSubdomain) return path ? ['account', (accountDetails as AccountInfoResponseType).accountId, EVENT, path] : null;
    return [EVENT, subdomain];
  };

  return useSWR(keyFn, fetchEventPrivateDetails);
}
