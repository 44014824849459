import React, { useMemo } from 'react';
import { PrivateMeetingSessionDetailsListResponseType, ReplaysListResponseType } from '../../../lib/api';
import ReplaysFeatureMeetingTile from './ReplaysFeatureMeetingTile';
import ReplaysMeetingTile from './ReplaysMeetingTile';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import './ReplaysWrapper.scss';

interface IProps {
  sessions: ReplaysListResponseType | void;
  event: EventType;
}

interface ISessionGroupByTrack {
  track: string;
  featuredSessions: PrivateMeetingSessionDetailsListResponseType;
  sessions: PrivateMeetingSessionDetailsListResponseType;
}

const ReplaysSessionsByTrack = (props: IProps): JSX.Element | null => {
  const { sessions = [], event } = props;

  const tracks = useMemo(() => {
    const tracks = [...new Set(sessions.map(meeting => meeting.track).sort((t1, t2) => t1?.localeCompare(t2)))];
    const indexNoTrackMeetings = [...tracks].findIndex(track => !track);
    tracks.push(tracks.splice(indexNoTrackMeetings, 1)[0]);
    return tracks;
  }, [sessions]);


  const sessionsGroupedByTrack: ISessionGroupByTrack[] = useMemo(() => {
    const sessionsGroupedByTrack = tracks.map(track => {
      return {
        track : track,
        featuredSessions: [] as PrivateMeetingSessionDetailsListResponseType,
        sessions: [] as PrivateMeetingSessionDetailsListResponseType
      };
    });
    sessionsGroupedByTrack.forEach((sessionsGroupedByTrack) => {
      sessions.forEach(meeting => {
        if (sessionsGroupedByTrack.track === meeting.track) {
          meeting.featured ? sessionsGroupedByTrack.featuredSessions.push(meeting) : sessionsGroupedByTrack.sessions.push(meeting);
        }
      });
    });
    return sessionsGroupedByTrack;
  }, [sessions, tracks]);

  return (
    <div className='meeting-card-grouped-track'>
      {sessionsGroupedByTrack.map((meeting, index) => {
        const sessionsCount = meeting.sessions.length + meeting.featuredSessions.length;
        return (<div key={index} className='meeting-card-grouped-track-wrapper'>
          <div className='meeting-card-grouped-track__title-block'>
            <span className='meeting-card-grouped-track__title'>{meeting.track}</span>
            <span className='meeting-card-grouped-track__count-title'> {sessionsCount} {sessionsCount ? 'Sessions' : 'Session'} </span>
          </div>
          <div className='meeting-card-grouped-track__card-wrapper'>
            {meeting.featuredSessions.map(featuredMeeting => {
              return (
                <ReplaysFeatureMeetingTile
                  key={featuredMeeting.meetingId}
                  meeting={featuredMeeting}
                  timeZone={event.timeZone}
                />
              );
            })}

            {meeting.sessions.map(session => {
              return (
                <ReplaysMeetingTile
                  key={session.meetingId}
                  event={event}
                  meeting={session}
                  timeZone={event.timeZone}
                />
              );
            })}
          </div>
          <div className='meeting-card-grouped-track-wrapper__line-block'>
            <div className='meeting-card-grouped-track-wrapper__line'/>
          </div>
        </div>);
      })
      }
    </div>
  );
};

export default ReplaysSessionsByTrack;