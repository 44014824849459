import React, { createContext, useContext, useState, ReactNode } from 'react';
import NotificationContainer from './NotificationContainer';

const MAX_TOASTS = 5;
const ToastContext = createContext<{ addToast: (message: string, isCalendarNotification?: boolean) => void } | undefined>(undefined);

export const useToast = (): { addToast: (message: string, isCalendarNotification?: boolean) => void } => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<{ id: number; message: string; isCalendarNotification?: boolean }[]>([]);

  const addToast = (message: string, isCalendarNotification?: boolean) => {
    const id = new Date().getTime();
    setToasts((prevToasts) => {
      const newToasts = [...prevToasts, { message, id, isCalendarNotification }];
      if (newToasts.length > MAX_TOASTS) {
        newToasts.shift();
      }
      return newToasts;
    });
  };

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <NotificationContainer toasts={toasts} onRemove={removeToast} />
    </ToastContext.Provider>
  );
};