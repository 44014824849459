import React from 'react';
import { ResponseError } from '../../config/api';

interface IProps {
  error?: ResponseError;
}

const EventError: React.FC<IProps> = ({ error }) => (
  <div className='grid grid-cols-2 justify-items-auto event-error'>
    <div className='flex flex-col justify-center items-center divide-y-2 divide-gray-500 divide-dashed'>
      <div className='font-medium text-5xl tracking-wide uppercase'>Event does not exist</div>
      <code className='font-mono text-gray-900'>{error?.message}</code>
    </div>
  </div>
);

export default EventError;
