import React, { useEffect, useState, useRef, RefObject } from 'react';
import Button from '.';
import './OptionsButton.scss';
import Icon from '../../components/_base/Icon';
import { MediaQueryContextType, useMediaQuery } from '../../lib/context-providers/media-query-provider';
import { CALENDAR_INVITES } from '../../lib/constants';
export interface ButtonProp {
  label: string;
  name: string;
  disabled?: boolean;
  className?: string | null;
  eventId: string;
  eventName: string;
  iconButton?: boolean;
  downloadIcsFile: () => void;
  openGoogleCalendar: () => void;
  unSubscribeToCalendar?: () => void;
  isSubscribeToEvent?: boolean;
  withCalendarIcon?: boolean;
  customStyles?: React.CSSProperties;
  calendarInvite?: CALENDAR_INVITES;
  isSubscribed?: boolean;
  onShowDropDown?: (ref: RefObject<HTMLDivElement>) => void;
}

export const OptionsButton: React.FunctionComponent<ButtonProp> = (props: ButtonProp): JSX.Element => {
  const {
    label,
    name,
    disabled,
    className = '',
    iconButton = false,
    downloadIcsFile,
    openGoogleCalendar,
    unSubscribeToCalendar,
    isSubscribeToEvent = false,
    calendarInvite,
    withCalendarIcon = false,
    customStyles = {},
    onShowDropDown,
    isSubscribed
  } = props;
  const ariaLabelForToggleButton = 'Add to Calendar options';

  const mediaQuery = useMediaQuery() as MediaQueryContextType;
  const isMobile = mediaQuery?.isMobile;

  const optionsButtonRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);

  const handleOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (optionsButtonRef?.current?.contains(target)) {
      return;
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropDown = () => {
    if (!showDropDown && onShowDropDown) onShowDropDown(optionsButtonRef);
    setShowDropDown(prev => !prev);
  };

  const closeItem = () => {
    setShowDropDown(false);
  };

  const getListItems = () => {
    return (
      <div className={iconButton ? 'select-box' : ''}>
        <div
          style={{ ...customStyles }}
          className={`select-box--items ${showDropDown ? 'block' : 'hidden'} ${iconButton ? 'select-box--right' : ''}`}
          onClick={() => closeItem()}
        >
          {(calendarInvite === CALENDAR_INVITES.Both || calendarInvite === CALENDAR_INVITES.subscribe) &&
            <Button.Common
              name='unsubscribe'
              ariaLabel='unsubscribe'
              className='select-box--items--option bg-cool-gray-hover'
              onClick={unSubscribeToCalendar}
            >
              <Icon.RemoveFromCalendar width='29px' height='30px' viewBox='0 0 50 50' className={'mr-1'}/>
              <span className='select-box--option'>Unsubscribe</span>
            </Button.Common>
          }
          {(!calendarInvite || calendarInvite === CALENDAR_INVITES.Both || calendarInvite === CALENDAR_INVITES.Download) &&
            <>
              <Button.Common
                name='addtoGoogleCalendar'
                ariaLabel='Add to Google Calendar'
                className='select-box--items--option bg-cool-gray-hover '
                onClick={openGoogleCalendar}
              >
                <Icon.GoogleCalendar width='30px' height='30px' viewBox="0 0 50 50" />
                <span className='select-box--option'>Add to Google Calendar</span>
              </Button.Common>
              <Button.Common
                className='bg-cool-gray-hover'
                name={`${props.eventName}.ics`}
                ariaLabel='Add to Outlook Calendar'
                onClick={() => {
                  downloadIcsFile();
                } }
              >
                <Icon.OutlookCalendar width='30px' height='30px' viewBox="0 0 50 50" />
                <span className='select-box--option'>{calendarInvite === CALENDAR_INVITES.Both ? 'Download Outlook file' : 'Add to Outlook Calendar (.ics)' }</span>
              </Button.Common>
            </>
          }
        </div>
      </div>
    );
  };

  return (
    <div ref={optionsButtonRef} className={`${!isMobile && !isSubscribeToEvent ? 'select-box--container' : ''}`}>
      {iconButton ?
        <Button.Common
          name={name}
          onClick={() => toggleDropDown()}
          className='calendar-button transparent-bg without-after'
          ariaLabel={ariaLabelForToggleButton}
          aria-haspopup='true'
          aria-expanded={showDropDown ? 'true' : 'false'}
        >
          <div className='on-hover-shadow px-2 pb-2 rounded inline-block'>
            <Icon.AddToCalendar width='24px' height='25px' viewBox='0 0 50 50' className='fill-primary' />
          </div>
        </Button.Common>
        :
        <Button.Common
          ariaLabel={ariaLabelForToggleButton}
          name={name}
          onClick={() => toggleDropDown()}
          label={label}
          disabled={disabled}
          className={`add-to-calendar-btn rounded text-center bg-white outline outline-mid-gray on-hover-shadow font-medium text-primary font-size-13px ${className} ${showDropDown ? `select-box--button` : ``}`}
        >
          {isSubscribed && <Icon.CheckedCalendar width='25px' height='25px' viewBox='0 0 50 50' className={`col order-first mr-2`} />}
          {withCalendarIcon && !isSubscribed && <Icon.AddToCalendar width='20px' height='20px' viewBox='0 0 50 50' className='fill-primary fill-white col order-first mr-4' />}
        </Button.Common>
      }
      {getListItems()}
    </div>
  );
};

export default OptionsButton;
