import React, { useEffect, useRef, useState } from 'react';
import './AttendeeWithDetails.scss';
import { MeetingAttendeeDetailsType, SpeakerResponseType } from '../../../lib/api';
import { ATTENDEE_COLLAPSED_BIO_MAX_HEIGHT, PassportNonce } from '../../../lib/constants';
import { MoreButton } from '../MoreButton';
import SafeHTMLRender from '../SafeHTMLRender';

interface IProps {
  attendee: MeetingAttendeeDetailsType | SpeakerResponseType;
  organizationName: string;
}

const AttendeeWithDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { attendee, organizationName } = props;
  const [bioExpanded, setBioExpanded] = useState(true);
  const [toggleVisible, setToggleVisible] = useState(false);
  const attendeeRef = useRef(null);
  const bioRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore*/
    const bioHeight = bioRef.current?.clientHeight || 0;
    if (bioHeight > ATTENDEE_COLLAPSED_BIO_MAX_HEIGHT) {
      setBioExpanded(false);
      setToggleVisible(true);
    }
  }, []);

  return (
    <>
      <div ref={attendeeRef} className={'attendee'}>
        <div className={'attendee__details'}>
          <h3 className={'font-size-16px line-height-19px'}>{attendee.firstName} {attendee.lastName}</h3>
          <div className={'attendee__title font-size-13px line-height-16px'}>
            {attendee.title}
          </div>
          <div className={'attendee__organization font-medium font-size-13px line-height-16px'}>
            {organizationName}
          </div>
          {attendee.biography && <div className={`attendee__bio attendee__bio${bioExpanded ? '_expanded' : ''}`}>
            <div ref={bioRef}>
              <SafeHTMLRender 
                nonce={PassportNonce.RICH_TEXT} 
                html={attendee.biography} 
                className='font-size-12px line-height-15px'
              />
            </div>
            {toggleVisible &&
              <MoreButton
                action={() => setBioExpanded(!bioExpanded)}
                isContentExpanded={bioExpanded}
                ariaLabel={'toggle bio'}
                className={bioExpanded ? 'expanded' : ''}
              />
            }
          </div>}
        </div>
        <div className="attendee__photo">
          {attendee.profileImageUrl && <img src={attendee.profileImageUrl} alt={'Speaker'} />}
        </div>
      </div>
      <hr />
    </>
  );
};

export default AttendeeWithDetails;
