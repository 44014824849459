import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { decodeJwt } from "jose";
import DefaultSpinner from "../shared/DefaultSpinner";
import { ROOT } from "../../config/routes/paths";
import { useRegistrationSubscription } from "../../hooks/use-registration-subscription";
import { initiateAttendeeRegistration } from "../../lib/api/public";
import { usePathPrefix } from '../../hooks/use-path-prefix';
import { normalizeRoutePath } from '../../lib/helpers/urlHelper';

type JwtPayload = {
  registrationId?: string;
};

type Props = {
  token: string;
};

export const LoadingPage: React.FC<Props> = (props: Props) => {
  const { state, subscribe } = useRegistrationSubscription();
  const [showSubscriptionError, setSubscriptionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const pathPrefix = usePathPrefix();

  const token = props.token;
  const subscriptionInProgress = state.inProgress || !!(state.magicLink && !state.error);

  const handleSubscription = async (registrationId: string) => {
    try {
      await subscribe({ registrationId: registrationId, appSyncToken: token });
      await initiateAttendeeRegistration({ registrationId: registrationId, appSyncToken: token, registrationApprovalEnabled: false });
    } catch (error) {
      setSubscriptionError(true);
      setErrorMessage("Subscription failed. Please try again.");
    }
  };

  useEffect(() => {
    if (!token) return;

    let registrationId: string | undefined;
    try {
      const decoded: JwtPayload = decodeJwt(token) as JwtPayload;
      registrationId = decoded.registrationId;
    } catch (error) {
      setErrorMessage("Invalid token format.");
      return;
    }

    if (registrationId) {
      handleSubscription(registrationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!subscriptionInProgress && !showSubscriptionError) {
      setSubscriptionError(true);
    }
  }, [showSubscriptionError, subscriptionInProgress]);

  if (!token) {
    return <Redirect to={normalizeRoutePath(ROOT, pathPrefix)} />;
  }

  return (
    <div className="flex justify-center items-center h-64">
      {subscriptionInProgress && (
        <DefaultSpinner visible={subscriptionInProgress} />
      )}
      {(showSubscriptionError || errorMessage) && (
        <>
          <span>{state.error?.message || errorMessage}</span>
          <hr className="mt-4 mb-4" />
        </>
      )}
    </div>
  );
};