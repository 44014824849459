import React, { SyntheticEvent, useState } from 'react';
import Modal from 'react-modal';
import { getKvContentUrl } from '../../../lib/api/utils';
import { THUMBNAIL } from '../../../lib/constants';
import { EmbedContainer } from '../../../lib/kv-embed';
import Icon from '../../../components/_base/Icon';
import Button from '../../../components/button';
import './KnovioThumbnail.scss';

interface IProps {
  welcomeKnovioEmbedId: string
}

const KnovioThumbnail = (props: IProps): JSX.Element => {
  const [ openThumbnail, setOpenThumbnail ] = useState<boolean>(false);

  const className = `editable-welcome-knovio`;
  const classNameKnovioWrapper = `${className}--wrapper`;

  const toggleModal = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenThumbnail(!openThumbnail);
  };

  if (Modal && Modal.defaultStyles) {
    if (Modal.defaultStyles.content) {
      Modal.defaultStyles.content.border = 'none';
      Modal.defaultStyles.content.borderRadius = 0;
      Modal.defaultStyles.content.padding = 0;
      Modal.defaultStyles.content.top = '40px';
    }
    if (Modal.defaultStyles.overlay) {
      Modal.defaultStyles.overlay.zIndex = 3;
      Modal.defaultStyles.overlay.background = 'rgba(0,0,0,0.75)';
    }
  }

  return (
    <Button.Common
      name='knovio-video'
      className={`${className} height-min-100 block w-80 mx-auto bg-black sm-shadow-gray relative cursor-pointer player`}
      onClick={toggleModal}
      aria-label='Button that reproduces a knovio'
    >
      <Modal isOpen={openThumbnail} ariaHideApp={false} onRequestClose={toggleModal}>
        <>
          <button
            name='close'
            aria-label='Close Knovio'
            className='fixed line-height-40px top-0 right-0 text-center w-10'
          >
            <span className='cursor-pointer text-white font-size-24px font-bold' onClick={toggleModal}>&#x2715;</span>
          </button>
          <EmbedContainer.Knovio embedId={props.welcomeKnovioEmbedId} />
        </>
      </Modal>
      <img
        className='h-full w-full object-contain'
        alt='knovio thumbnail'
        src={`${getKvContentUrl()}/${THUMBNAIL}/${props.welcomeKnovioEmbedId}`}
      />
      <span className={`${classNameKnovioWrapper} absolute top-0 left-0 w-full h-full flex`}>
        <span className='m-auto rounded-full h-12 w-12 p-left-10px p-top-7px bg-gray-50'>
          <span className='fill-current text-white'>
            <Icon.RightArrow
              aria-labelledby='play-button'
              aria-describedby='desc-for-play-button'
              width='35px'
              height='35px'
            />
          </span>
        </span>
      </span>
    </Button.Common>
  );
};

export default KnovioThumbnail;
