import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { isSameDay } from 'date-fns';
import { getFormatTimezone, getFullDateInUsEuFormat, getTimeInLocalFormat } from '../../../../lib/helpers/dateHelper';
import { GetMeetingStatus, IMeetingTimingBlockProps } from '../../../shared/MeetingTimingBlock';

import './GridMeetingTimingBlock.scss';

const GridMeetingTimingBlock: React.FC<IMeetingTimingBlockProps> = (props: IMeetingTimingBlockProps) => {
  const { startDateTime, endDateTime } = props.meetingDetails;
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedHostStartTime = getTimeInLocalFormat(startDateTime, props.timeZone);
  const formattedAttendeeStartTime = getTimeInLocalFormat(startDateTime, currentTimeZone);
  const attendeeStartDateTime = utcToZonedTime(startDateTime, currentTimeZone);
  const attendeeOffsetGMT = getFormatTimezone(attendeeStartDateTime);
  const formattedHostEndTime = getTimeInLocalFormat(endDateTime, props.timeZone);
  const attendeeEndDateTime = utcToZonedTime(endDateTime, currentTimeZone);
  const eventStartDateTime = new Date(startDateTime);
  const className = 'editable-session';
  const classNameSessionTableRowLeftDate = `${className}--table-row-left-date`;
  const contentDateStyles = props.transformedTimeZone ? 'overflow-hidden' : '';
  const mobileAlignmentStyles = props.isMobile ? 'items-normal' : 'items-center';
  const fullDateClassName = `text-gray-700 font-size-10px flex ${mobileAlignmentStyles} ${props.isMobile ? ' line-height-22px' : ''}`;

  const isEventTimezoneTimeDifferents = (): boolean => {
    return (getTimeInLocalFormat(attendeeStartDateTime, currentTimeZone) === getTimeInLocalFormat(eventStartDateTime, props.timeZone));
  };

  return (
    <>
      <div className={`${mobileAlignmentStyles} flex grid-timing-block`}>
        <p className={`${classNameSessionTableRowLeftDate} ${contentDateStyles} ${mobileAlignmentStyles} flex font-size-15px lowercase tracking-normal`}>
          <span className='mr-1 inline-block grid-timing-block__time'>
            {formattedHostStartTime}{props.hideEndDateTime || ` - ${formattedHostEndTime}`}
          </span>
          <span className='normal-case word-break'>{props.transformedTimeZone} &nbsp;</span>
        </p>
        <div className={fullDateClassName}>
          {!isSameDay(eventStartDateTime, utcToZonedTime(startDateTime, props.timeZone)) &&
            <span className='text-gray-700 font-size-10px'>
              {getFullDateInUsEuFormat(eventStartDateTime, currentTimeZone)} &nbsp;
            </span>
          }
          {!isEventTimezoneTimeDifferents() &&
            <span className='lowercase'>
              {formattedAttendeeStartTime} &nbsp;({attendeeOffsetGMT})&nbsp;
            </span>
          }
        </div>
        <GetMeetingStatus
          attendeeStartDateTime={attendeeStartDateTime}
          attendeeEndDateTime={attendeeEndDateTime}
          isMultiMeetingsBlock={props.isMultiMeetingsBlock}
          classname='p-top-0px line-height-22px'
        />
      </div>
    </>
  );
};

export default GridMeetingTimingBlock;
