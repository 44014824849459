import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import API, { AccountInfoResponseType, EventPublicDetailsResponseType, PUBLIC } from '../../../lib/api';
import { ERROR_SUBDOMAIN } from '../../../lib/constants';
import useSubdomain from '../../use-subdomain';
import { AccountContextType, useAccountContext } from '../../../lib/context-providers/account-context';
import { getFirstPathSegmentFromLocation } from '../../../lib/helpers/urlHelper';

const { fetchPublicEventDetailsBySubdomain, fetchPublicEventDetailsByAccount } = API[PUBLIC];

/* bypassing api calls from MeetLinks and FileDownload to Passport API */
export function useEventPublicInfo(bypassApiCall = false): responseInterface<EventPublicDetailsResponseType, ResponseError> {
  const subdomain = useSubdomain();
  const path = getFirstPathSegmentFromLocation();

  const { accountDetails, isAccountSubdomain } = useAccountContext() as AccountContextType;

  const _keyFn = () => {
    if (bypassApiCall || subdomain === ERROR_SUBDOMAIN) return null;
    if (isAccountSubdomain) return path ? [(accountDetails as AccountInfoResponseType).accountId, path] : null;
    return subdomain;
  };

  const apiCall = isAccountSubdomain ? fetchPublicEventDetailsByAccount : fetchPublicEventDetailsBySubdomain;

  const swrResponse = useSWR(_keyFn, apiCall);

  return swrResponse;
}
