import React, { useEffect, useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { formatDistance, isSameDay } from 'date-fns';
import { getFormatTimezone, getFullDateInUsEuFormat, getTimeInLocalFormat } from '../../lib/helpers/dateHelper';
import { HALF_A_MINUTE_IN_MILLISECONDS, TIME_RANGES } from '../../lib/constants';
import './MeetingTimingBlock.scss';

export interface IMeetingTimingBlockProps {
  meetingDetails: {
    startDateTime: string | Date,
    endDateTime: string | Date,
    eventName?: string,
    eventId?: string
  }
  timeZone: string,
  hideStartDate?: boolean,
  hideEndDateTime?: boolean,
  isMultiMeetingsBlock?: boolean,
  primaryColor?: string,
  transformedTimeZone?: string,
  isMobile?: boolean | void;
}

interface IGetMeetingStatusProps {
  attendeeStartDateTime: Date;
  attendeeEndDateTime: Date;
  isMultiMeetingsBlock: boolean | undefined;
  classname?: string;
}

export const GetMeetingStatus: React.FC<IGetMeetingStatusProps> = (props: IGetMeetingStatusProps) => {
  const [timeLeftToTheMeeting, setTimeLeftToTheMeeting] = useState<string>(formatDistance(props.attendeeStartDateTime, new Date()));
  const [isMeetingInProgress, setIsMeetingInProgress] = useState<boolean | undefined>(false);
  const [meetingTimer, setMeetingTimer] = useState<number>(HALF_A_MINUTE_IN_MILLISECONDS);
  const additionalClassName = props.classname ? props.classname : '';

  const newDate = new Date();
  const { attendeeEndDateTime, attendeeStartDateTime, isMultiMeetingsBlock } = props;

  useEffect(() => {

    if (attendeeStartDateTime.getTime() - new Date().getTime() > TIME_RANGES.MILLISECONDS_IN_HOUR ||
      attendeeEndDateTime < new Date()) {
      return;
    }

    const myInterval = () => {
      const newDateInTimer = new Date();
      const timeToStartMeeting = attendeeStartDateTime.getTime() - newDateInTimer.getTime();

      if (attendeeEndDateTime < newDateInTimer && isMeetingInProgress === false) {
        setMeetingTimer(prev => TIME_RANGES.MILLISECONDS_IN_SECOND);
        setIsMeetingInProgress(prev => undefined);
      }

      if (attendeeEndDateTime < newDateInTimer && isMeetingInProgress === true) {
        setMeetingTimer(prev => TIME_RANGES.MILLISECONDS_IN_SECOND);
        setIsMeetingInProgress(prev => undefined);
      }

      if (attendeeEndDateTime < newDateInTimer && isMeetingInProgress === undefined) {
        window.clearInterval(currentInterval);
      }

      if (attendeeStartDateTime < newDateInTimer && attendeeEndDateTime > newDateInTimer && isMeetingInProgress === false) {
        setIsMeetingInProgress(prev => true);
        setMeetingTimer(prev => attendeeEndDateTime.getTime() - newDateInTimer.getTime());
      }

      if (timeToStartMeeting < TIME_RANGES.MILLISECONDS_IN_HOUR &&
          timeToStartMeeting > -HALF_A_MINUTE_IN_MILLISECONDS) {
        setTimeLeftToTheMeeting(prev => formatDistance(attendeeStartDateTime, newDateInTimer));
      }
    };

    const currentInterval = window.setInterval(myInterval, meetingTimer);

    return () => {
      window.clearInterval(currentInterval);
    };
  }, [attendeeStartDateTime, attendeeEndDateTime, isMeetingInProgress, meetingTimer]);

  if (attendeeEndDateTime < newDate) {
    return (
      <p className={additionalClassName + ' text-dark-red italic font-size-11px pt-1 meeting-status-text'}>
        {isMultiMeetingsBlock ? 'These meetings are in the past' : 'This meeting is in the past'}
      </p>);
  }
  if (attendeeStartDateTime < newDate ){
    return (<p className={additionalClassName + ' text-primary italic font-size-11px pt-1 font-bold'}>In progress</p>);
  }
  if ((attendeeStartDateTime.getTime() - newDate.getTime()) > TIME_RANGES.MILLISECONDS_IN_HOUR) {
    return null;
  }
  return (
    <p className={additionalClassName + ' text-primary italic font-size-11px pt-1'}>
      Begins in {timeLeftToTheMeeting}
    </p>);
};

const MeetingTimingBlock: React.FC<IMeetingTimingBlockProps> = (props: IMeetingTimingBlockProps) => {
  const { startDateTime, endDateTime } = props.meetingDetails;
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedHostStartDate = getFullDateInUsEuFormat(startDateTime, props.timeZone);
  const formattedHostStartTime = getTimeInLocalFormat(startDateTime, props.timeZone);
  const formattedAttendeeStartTime = getTimeInLocalFormat(startDateTime, currentTimeZone);
  const attendeeStartDateTime = utcToZonedTime(startDateTime, currentTimeZone);
  const attendeeOffsetGMT = getFormatTimezone(attendeeStartDateTime);
  const formattedHostEndTime = getTimeInLocalFormat(endDateTime, props.timeZone);
  const formattedAttendeeEndTime = getTimeInLocalFormat(endDateTime, currentTimeZone);
  const attendeeEndDateTime = utcToZonedTime(endDateTime, currentTimeZone);
  const eventStartDateTime = new Date(startDateTime);
  const className = 'editable-session';
  const classNameSessionTableRowLeftDate = `${className}--table-row-left-date`;
  const contentDateStyles = props.transformedTimeZone ? 'overflow-hidden' : '';

  const isEventTimezoneTimeDifferents = (): boolean => {
    return (getTimeInLocalFormat(attendeeStartDateTime, currentTimeZone) === getTimeInLocalFormat(eventStartDateTime, props.timeZone));
  };

  return (
    <>
      {props.hideStartDate ||
        <h1 className='text-primary font-size-14px font-bold uppercase tracking-widest'>
          {formattedHostStartDate}
        </h1>}
      <p className={`${classNameSessionTableRowLeftDate} ${contentDateStyles} font-size-15px pt-2 lowercase tracking-normal`}>
        <span className='mr-1 inline-block'>
          {formattedHostStartTime}{props.hideEndDateTime || ` - ${formattedHostEndTime}`}
        </span>
        <span className='normal-case word-break'>{props.transformedTimeZone}</span>
      </p>
      {!isSameDay(eventStartDateTime, utcToZonedTime(startDateTime, props.timeZone)) &&
        <div className='text-gray-700 font-size-10px pt-1'>
          {getFullDateInUsEuFormat(eventStartDateTime, currentTimeZone)}
        </div>}
      {!isEventTimezoneTimeDifferents() && <p className='text-gray-700 font-size-10px pt-1'>
        <span className='lowercase'>
          {formattedAttendeeStartTime}{props.hideEndDateTime || ` - ${formattedAttendeeEndTime}`}
        </span>
        &nbsp;({attendeeOffsetGMT})
      </p>}
      <GetMeetingStatus
        attendeeStartDateTime={attendeeStartDateTime}
        attendeeEndDateTime={attendeeEndDateTime}
        isMultiMeetingsBlock={props.isMultiMeetingsBlock}
      />
    </>
  );
};

export default MeetingTimingBlock;
