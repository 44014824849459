import React, { useRef, RefObject, useEffect, useState, useLayoutEffect } from 'react';
import { PrivateMeetingSessionDetailsResponseType } from '../../../../lib/api';
import { TimingBlock } from './TimingBlock';
import { FooterButtons } from './FooterButtons';
import { EventType } from '../LayoutWrapper';
import { truncate } from '../helpers';
import { IAuthContext, useAuth } from '../../../../lib/context-providers/auth-context';
import { MeetingOrganizationsList } from '../MeetingOrganizationsList/MeetingOrganizationsList';
import { MeetingTopic } from '../MeetingTopic';
import { useHistory } from 'react-router-dom';
import { SESSION_PATH } from '../../../../config/routes/paths';
import { MoreButton } from '../../../shared/MoreButton';
import './Card.scss';
import { usePathPrefix } from '../../../../hooks/use-path-prefix';
import { normalizeRoutePath } from '../../../../lib/helpers/urlHelper';
import { HOSTED_BY, PassportNonce } from '../../../../lib/constants';
import SafeHTMLRender from '../../../shared/SafeHTMLRender';

export interface ICardProps {
  index?: number;
  event: EventType;
  meeting: PrivateMeetingSessionDetailsResponseType;
  featured?: boolean;
  eventToken: string;
  transformedTimeZone: string;
  isGridLayout?: boolean;
}

const limit = 76;
const EXPAND_HEIGHT_LIMIT = 280;
const EXPAND_WIDE_HEIGHT_LIMIT = 330;

const useCardScrollHeight = (ref: React.RefObject<HTMLDivElement>) => {
  const [scrollHeight, setScrollHeight] = useState(ref.current?.scrollHeight ?? 0);

  useLayoutEffect(() => {
    setScrollHeight(() => ref.current?.scrollHeight ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.scrollHeight]);

  return { scrollHeight };
};

const CardComponent = (props: ICardProps): JSX.Element => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;
  const pathPrefix = usePathPrefix();

  const topicRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const {
    meeting,
    event,
    featured = meeting.featured,
    eventToken,
    transformedTimeZone,
    isGridLayout,
  } = props;

  const { startDateTime,
    endDateTime,
    topic,
    thumbnailImageUrl,
    notes,
    presenterOrganizations
  } = meeting;

  const [contentExpanded, setContentExpanded] = useState(false);
  const [toggleVisible, setToggleVisible] = useState(false);
  const history = useHistory();

  const meetingClassNamePrefix = 'meeting-card';
  const featuredClassNamePrefix = 'featured-card';
  const prefix = featured ? featuredClassNamePrefix : meetingClassNamePrefix;

  const thumbnailImageUrlClassName = !thumbnailImageUrl ? ` ${prefix}__image--empty` : '';
  const contentExpandedClassName = contentExpanded ? ` ${prefix}--expanded` : '';
  const expandableContentRef = useRef<HTMLDivElement>(null);
  const additionalMoreClassName = isAuthenticated && !event.preRegistrationEnabled ? 'pb-0' : '';
  const wideHeightClassName = !isAuthenticated ? 'wide-height' : '';
  const expandHeightLimit = isAuthenticated ? EXPAND_HEIGHT_LIMIT : EXPAND_WIDE_HEIGHT_LIMIT;
  useCardScrollHeight(expandableContentRef);

  useEffect(() => {
    if (topicRef.current && !featured) {
      const text = truncate(topicRef.current.textContent ?? '', limit);
      topicRef.current.textContent = text;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const expandableScrollHeight = expandableContentRef.current?.scrollHeight ?? 0;

    if (expandableScrollHeight > expandHeightLimit) {
      setContentExpanded(false);
      setToggleVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandableContentRef.current]);

  return (
    <div
      className={`general-session-card ${prefix}${thumbnailImageUrlClassName}${contentExpandedClassName}`}
      data-meeting-id={meeting.meetingId}
    >
      <div className={`${prefix}__content`}>
        <div
          className={`${prefix}__expandable-content ${prefix}__content-inner ${wideHeightClassName}`}
          ref={expandableContentRef}
        >
          {thumbnailImageUrl &&
            <div className={`${prefix}__img`}>
              <img
                src={thumbnailImageUrl}
                alt='thumbnailImageUrl'
                title='thumbnailImageUrl'
              />
            </div>
          }

          <TimingBlock
            meetingDetails={{
              startDateTime,
              endDateTime
            }}
            featured={featured}
            hideStartDate={true}
            hideEndDateTime={true}
            timeZone={event.timeZone}
            isMultiMeetingsBlock={false}
            transformedTimeZone={transformedTimeZone}
          />
          <MeetingTopic
            orgs={presenterOrganizations ?? []}
            topic={topic}
            isFeatured={featured}
            classNames={`${prefix}__content-topic`}
          />
          <MeetingOrganizationsList
            orgs={meeting.presenterOrganizations ?? []}
            organizationClassName={`${prefix}__organizations-list-item`}
            wrapperClassName={`${prefix}__organizations-list`}
          />
          <MeetingOrganizationsList
            orgs={meeting.hostOrganizations ?? []}
            organizationClassName={`${prefix}__organizations-list-item`}
            wrapperClassName={`${prefix}__organizations-list`}
            organizationHeader={HOSTED_BY}
          />
          {notes && <SafeHTMLRender nonce={PassportNonce.RICH_TEXT} html={notes} className='flex flex-col notes word-break overflow-hidden'/>}
        </div>
        <div className={`${prefix}__content-footer-buttons`}>
          {toggleVisible &&
            <div className={`${prefix}__content-inner ${prefix}__content-inner-more-button ${additionalMoreClassName} mt-1`}>
              <MoreButton
                action={() => setContentExpanded(prev => !prev)}
                isContentExpanded={contentExpanded}
                ariaLabel='toggle content'
                className={`${prefix}__content-more-button`}
              />
            </div>
          }
          {isAuthenticated &&
            <FooterButtons
              event={event}
              meeting={meeting}
              isCarousel={!isGridLayout}
              isSessionsTab={history.location.pathname === normalizeRoutePath(SESSION_PATH, pathPrefix)}
              withTooltip={!featured}
              featured={featured}
              eventToken={eventToken}
            />
          }
        </div>
      </div>
    </div>
  );
};

export { CardComponent };
