import React from 'react';
import TextAsHTML from "../../../components/_base/TextAsHTML";
import './RichTextPageInfo.scss';

interface IProps {
  richText: string;
  label: string;
}
const className = 'editable-rich-text';
const classNameRichTextWrapper = `${className}--wrapper`;
const classNameRichTextDescription = `${className}--description`;
const classNameRichTextTitle = `${className}--title`;

const RichTextPageInfo = (props: IProps): JSX.Element => {

  return (
    <div className={`${classNameRichTextWrapper} overflow-hidden shadow-gray bg-white mx-auto md:w-3/4 mt-12 max-h-full h-full w-100 mx-0 px-0`}>
      <h1 className={`${classNameRichTextTitle} font-size-40px`}>{props?.label}</h1>
      <hr />
      <div className={`${classNameRichTextDescription} truncate-advanced-15`}>
        <TextAsHTML formattedText={props?.richText} />
      </div>
    </div>
  );
};

export default RichTextPageInfo;
