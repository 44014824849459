import React, { useRef } from 'react';
import { SessionLayout } from '../../../../lib/api/custom-page.types';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { CarouselWrapperByDay } from './CarouselWrapper';
import { EventType, IFilteredMeetings } from '../LayoutWrapper';
import './Card.scss';

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  searchedValue: string;
  setFilteredMeetingsCount: (data: IFilteredMeetings) => void;
  eventToken: string;
  transformedTimeZone: string;
}

const CarouselViewByDay = (props: ICarouselSection): JSX.Element => {
  const {
    event,
    meetings,
    showAllDates,
    showPastMeetings,
    searchedValue,
    setFilteredMeetingsCount,
    eventToken,
    transformedTimeZone,
  } = props;
  const isCarouselViewByDay = event?.layout === SessionLayout.CAROUSEL_VIEW_BY_DAY;
  const filteredMeetingsCount = useRef<IFilteredMeetings>({
    filteredMeetingsLength: 0,
  });
  const handleSetFilteredMeetingsCount = (data: IFilteredMeetings): void => {
    filteredMeetingsCount.current.filteredMeetingsLength = filteredMeetingsCount.current.filteredMeetingsLength + data.filteredMeetingsLength;
    setFilteredMeetingsCount(filteredMeetingsCount.current);
  };

  return (
    <>
      {
        Object.keys(meetings).map((date: string, index: number) => (
          <CarouselWrapperByDay
            key={date}
            event={event}
            selectedDate={date}
            meetings={meetings[date]}
            showAllDates={showAllDates}
            isCarouselViewByDay={isCarouselViewByDay}
            showPastMeetings={showPastMeetings}
            searchedValue={searchedValue}
            setFilteredMeetingsCount={data => handleSetFilteredMeetingsCount(data)}
            eventToken={eventToken}
            transformedTimeZone={transformedTimeZone}
          />
        ))
      }
    </>
  );
};

export { CarouselViewByDay };
