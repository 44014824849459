import React, { useEffect } from 'react';
import './NotificationWrapper.scss';

type ToastProps = {
  message: string;
  id: number;
  isCalendarNotification?: boolean;
  onRemove: (id: number) => void;
};

const NotificationWrapper: React.FC<ToastProps> = ({ message, id, onRemove, isCalendarNotification }: ToastProps): JSX.Element => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove(id);
    }, 3000);

    return () => clearTimeout(timer);
  }, [id, onRemove]);

  return (
    <div className="toast">
      {isCalendarNotification
        ? (
          <div className="text-center font-size-14px font-weight-500">
            We have emailed you a calendar invite for <strong>{message}</strong>.
            <br />
            Any changes to the schedule will stay up to date on your calendar.
          </div>
        )
        : (
          message
        )}
    </div>
  );
};

export default NotificationWrapper;