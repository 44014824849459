import { RoutesConfig } from "../config/routes";
import { ROOT } from '../config/routes/paths';
import { normalizeRoutePath } from '../lib/helpers/urlHelper';

export default function useLabelFromPath(path: string, routesConfig: RoutesConfig, pathPrefix = ''): string | undefined {
  /* used to extract the first path segment from the path, ex. /path/to/some/page => /path */
  const onePathSegmentRegex = /^\/[^/]+/;
  /* used to extract the first two segments from the path, ex. /path/to/some/page => /path/to */
  const twoPathSegmentRegex = /^\/[^/]+\/[^/]+/;

  const regex = pathPrefix ? twoPathSegmentRegex : onePathSegmentRegex;

  const result = path.match(regex);

  const pathKey = result ? result[0] : normalizeRoutePath(ROOT, pathPrefix);

  let label = routesConfig[pathKey]?.label;

  if (!label) {
    for (const [key, conf] of Object.entries(routesConfig)) {
      if (key.startsWith(pathKey)) {
        label = conf.label;
        break;
      }
    }
  }

  return label;
}
