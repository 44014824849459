import React, { FC, useEffect, useState } from 'react';

interface IProps {
    html: string;
    nonce?: string;
    className?: string;
}

const SafeHTMLRender: FC<IProps> = ({ html, nonce = '', className='' }) => {
  const randomMultiplicator = 1000;
  const [processedHTML, setProcessedHTML] = useState('');
  const [cssRules, setCssRules] = useState('');
  const htmlToRender = typeof html === 'string' ? html : '';
    
  useEffect(() => {
    const parser: DOMParser = new DOMParser();
    const doc: Document = parser.parseFromString(htmlToRender, 'text/html');

    let styles = '';
    // Process elements with inline styles
    const elementsWithStyle: NodeListOf<Element> = doc.querySelectorAll('[style]');

    elementsWithStyle.forEach((element, index) => {
      const style = element.getAttribute('style');
      if (style) {
        // Generate unique class name
        const className = `kv-player-dynamic-style-${Date.now()}-${(Math.floor(Math.random() * randomMultiplicator) + index)}`;
        styles += `.${className} { ${style} }\n`;

        // Replace style with class
        element.classList.add(className);
        element.removeAttribute('style');
      }
    });

    setProcessedHTML(doc.body.innerHTML);
    setCssRules(styles);
  }, [htmlToRender]);

  // Inject styles with nonce
  useEffect(() => {
    if (!cssRules) return;

    const styleTag: HTMLStyleElement = document.createElement('style');

    styleTag.setAttribute('nonce', nonce); // Add nonce value

    styleTag.textContent = cssRules;

    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cssRules]);

  return <div className={className} dangerouslySetInnerHTML={{ __html: processedHTML }} />;
};

export default SafeHTMLRender;
