import { PassportNonce } from "../../lib/constants";

export default (() => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const customStyle = document.createElement('style');
  customStyle.setAttribute("data-custom-css", "customCss");
  customStyle.setAttribute("type", "text/css");
  customStyle.setAttribute("nonce", PassportNonce.CUSTOM_CSS);
  head.appendChild(customStyle);
  customStyle.appendChild(document.createTextNode(''));
})();
