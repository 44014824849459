import React from 'react';
import './NotificationWrapper.scss';
import NotificationWrapper from './NotificationWrapper';

type ToastContainerProps = {
  toasts: { id: number; message: string; isCalendarNotification?: boolean }[];
  onRemove: (id: number) => void;
};

const NotificationContainer: React.FC<ToastContainerProps> = ({ toasts, onRemove }: ToastContainerProps): JSX.Element => {
  return (
    <div className="toast-container">
      {toasts.map((toast) => (
        <NotificationWrapper key={toast.id} {...toast} onRemove={onRemove}/>
      ))}
    </div>
  );
};

export default NotificationContainer;