import React, { createContext, useContext, useEffect, useState } from 'react';
import useSubdomain from '../../hooks/use-subdomain';
import API, { AccountInfoResponseType, PUBLIC } from '../api';
import { ERROR_SUBDOMAIN } from '../constants';
import GlobalStore from '../../app/global-store';
import { getFirstPathSegmentFromLocation } from '../helpers/urlHelper';

export type AccountContextType = {
  isAccountSubdomain: boolean;
  isValidating: boolean;
  accountDetails: AccountInfoResponseType | null;
};

export const useAccountContextProvider = (): AccountContextType => {
  const subdomain = useSubdomain();
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ accountDetails, setAccountDetails ] = useState<AccountInfoResponseType | null>(null);
  const [ isAccountSubdomain, setIsAccountSubdomain ] = useState<boolean>(false);

  const onDataFetched = (data: AccountInfoResponseType) => {
    setAccountDetails(data);
    setIsAccountSubdomain(true);
    GlobalStore.getInstance().eventSubdomain = getFirstPathSegmentFromLocation();
  };

  const onError = () => {
    setIsAccountSubdomain(false);
    setAccountDetails(null);
    GlobalStore.getInstance().eventSubdomain = subdomain;
  };

  const stopLoading = () => setLoading(false);
  const startLoading = () => setLoading(true);

  useEffect(() => {
    startLoading();

    if (!subdomain || subdomain === ERROR_SUBDOMAIN) {
      stopLoading();
      return;
    }

    API[PUBLIC].fetchAccountInfoBySubdomain(subdomain)
      .then(onDataFetched)
      .catch(onError)
      .finally(stopLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdomain]);

  return {
    isValidating: loading,
    isAccountSubdomain,
    accountDetails
  };
};

export const AccountContext = createContext<AccountContextType | void>(undefined);

export const useAccountContext = (): AccountContextType | void => useContext(AccountContext);

export const AccountContextProvider = (props: {children: React.ReactNode | React.ReactNodeArray}): JSX.Element => {
  const accountContext = useAccountContextProvider();

  return (
    <AccountContext.Provider value={accountContext}>
      {accountContext.isValidating ? null : props.children}
    </AccountContext.Provider>
  );
};