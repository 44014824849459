import { useEventPrivateInfo } from './api/protected/use-event-private-details';
import { normalizeRoutePath } from '../lib/helpers/urlHelper';
import { usePathPrefix } from './use-path-prefix';

export default function useEnabledRoute (route: string): string {
  const { data } = useEventPrivateInfo();
  const pathPrefix = usePathPrefix();

  const isLandingPageEnabled = data?.customPages.find(page => page.landingPage && page.enabled);
  if (isLandingPageEnabled) return normalizeRoutePath(route, pathPrefix);

  let firstEnabledRoute = normalizeRoutePath('', pathPrefix);
  if (data) {
    for (let index = 0; index < data.customPages.length; index++) {
      const element = data.customPages[index];
      if (element.enabled) {
        const path = `/${element.path ? element.path : element.type.toLowerCase()}`;
        firstEnabledRoute = normalizeRoutePath(path, pathPrefix);
        break;
      }
    }
  }
  return firstEnabledRoute;
}