import { CustomPageProps, defaultPagePathByType } from '../lib/api/custom-page.types';
import { useEventPrivateInfo } from './api/protected/use-event-private-details';
import { isDefaultPage } from './use-custom-routes';
import { normalizeRoutePath } from '../lib/helpers/urlHelper';
import { usePathPrefix } from './use-path-prefix';
import { useLocation } from 'react-router-dom';
import { CALENDAR_SERVICE_PATH } from '../config/routes/paths';


export default function useDefaultRoutePage(): string {
  const { data } = useEventPrivateInfo();
  const pathPrefix = usePathPrefix();
  const normalizedEmptyRoute = normalizeRoutePath('', pathPrefix);
  const { state } = useLocation<{ from?: string }>();

  if (state?.from?.endsWith(CALENDAR_SERVICE_PATH)) {
    return normalizeRoutePath(CALENDAR_SERVICE_PATH, pathPrefix);
  }

  if (!data || !data.customPages) return normalizedEmptyRoute;

  const pagebyLandingPageProp = data.customPages.find(route => route.landingPage && route.enabled) as CustomPageProps;
  if (pagebyLandingPageProp) {
    return isDefaultPage(pagebyLandingPageProp)
      ? normalizeRoutePath(defaultPagePathByType[pagebyLandingPageProp.type], pathPrefix)
      : normalizeRoutePath(pagebyLandingPageProp.path, pathPrefix);
  }

  const pagebyEnableProp = data.customPages.find(route => route.enabled) as CustomPageProps;
  if (pagebyEnableProp) {
    return isDefaultPage(pagebyEnableProp)
      ? normalizeRoutePath(defaultPagePathByType[pagebyEnableProp.type], pathPrefix)
      : normalizeRoutePath(pagebyEnableProp.path, pathPrefix);
  }

  return normalizedEmptyRoute;
}
