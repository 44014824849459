import React, { useEffect, useState, useRef, RefObject } from 'react';
import Icon from './Icon';
import './DialInSelect.scss';

export interface IDropdownOption {
  value: string;
  item?: {
    [key: string]: string | number;
  }
  
}

export type DialInSelectProps = {
  selectedOption: IDropdownOption;
  isCountryDropdown?: boolean;
  onChange(value: IDropdownOption): void;
  options: IDropdownOption[];
};

export const DialInSelect: React.FunctionComponent<DialInSelectProps> = (props: DialInSelectProps): JSX.Element => {
  const {
    isCountryDropdown,
    onChange,
    options,
    selectedOption,
  } = props;

  const selectRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const hasMultipleOptions = options.length > 1;

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const selectItem = (item: IDropdownOption) => {
    onChange(item);
    setShowDropDown(false);
  };

  const handleOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (selectRef?.current?.contains(target)) {
      return;
    }
    setShowDropDown(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleDropDown();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={selectRef} className='w-full'>
      <div className='phone-select__wrapper'>
        {hasMultipleOptions ?
          <div
            className={isCountryDropdown ? 'phone-select__selected-country-wrap' : 'phone-select__selected-number-wrap'}
            onClick={toggleDropDown}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <span>{selectedOption.value}</span>
            <Icon.RightArrow
              className={`${showDropDown ? 'phone-select__icon-active' : 'phone-select__icon'} fill-current ml-2 transform rotate-90 cursor-pointer`}
              height='10px'
              width='10px'
            />
          </div>
          : <span className='phone-select__one-option-number-wrap'>{selectedOption.value}</span>
        }
        <div
          className={`phone-select__options-wrapper ${showDropDown ? 'block' : 'hidden'}`}
        >
          <div className='inline-block'>
            <button
              onClick={toggleDropDown}
              tabIndex={0}
              className={`phone-select__selected-first-child ${showDropDown && 'active'}`}
              role='option'
              aria-selected={selectedOption.value === ''}
            >
              <span className='phone-select__option'>
                {selectedOption.value}
                <Icon.RightArrow
                  className={`${showDropDown ? 'phone-select__icon-active' : 'phone-select__icon'} fill-current ml-2 transform rotate-90 cursor-pointer`}
                  height="7px"
                  width="7px"
                />
              </span>
            </button>
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => selectItem(option)}
                className={`phone-select__option ${selectedOption.value === option.value ? 'selected' : ''}`}
                role='option'
                aria-selected={selectedOption.value === option.value}
              >
                <span>{option.value}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialInSelect;
