import { useEffect } from 'react';
import { useEventPublicInfo } from '../../../../hooks/api/public/use-event-public-details';
import useSubdomain from '../../../../hooks/use-subdomain';
import { getUrlQueryArgByKey } from '../../../api/utils';
import { IDP_IDENTIFIER } from '../../../constants';
import { IAuthContext, useAuth } from '../../auth-context';
import { AuthSSOInitiator } from './AuthSSOInitiator';
import { AppContextType, useApp } from '../../app-config-context';

export const useSSOAuthInitiator = (): void => {
  const auth = useAuth() as IAuthContext;
  const app = useApp() as AppContextType;
  const { data } = useEventPublicInfo();

  const subdomain = useSubdomain() || '';
  const idpIdentifier = getUrlQueryArgByKey(IDP_IDENTIFIER) || '';
  const idpProvider = data?.allowedProviders.includes(idpIdentifier) ? idpIdentifier : null;
  const resetBrowserUrl = () => window.history.replaceState({}, document.title, '/');

  useEffect(() => {
    if (data && auth.isAuthenticated === false && !auth.passwordlessAuthInProgress && idpProvider && subdomain && app.isConfigured) {
      const flowInitiator = new AuthSSOInitiator(idpProvider, subdomain);

      flowInitiator.initiate()
        .catch(resetBrowserUrl)
      ;

      auth.setPasswordlessAuthInProgress();
    }

  }, [data, auth, subdomain, idpProvider, app.isConfigured]);
};
