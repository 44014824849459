import React, { useState, useRef, RefObject, useEffect, useLayoutEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { PrivateMeetingSessionDetailsListResponseType } from '../../../../lib/api';
import { ARRAY_INCREMENT_SIZE } from '../../../../lib/constants';
import SessionsPagination, { IPagination, pageValues } from './SessionsPagination';
import { EventType, IGridFilteredMeetings } from '../LayoutWrapper';
import { getActiveTracks, getTileWidth } from '../helpers';
import { IMeetingsByStartTime } from '../LayoutWrapper';
import { useComposeMeeting } from '../useComposeMeeting';
import './GroupedList.scss';
import { useFilteredCarouselSessions } from '../../../../hooks/use-search-sessions';
import { CardComponent } from '../CarouselLayout/Card';
import GridMeetingTimingBlock from './GridMeetingTimingBlock';
import { getFullDateInUsEuFormat } from '../../../../lib/helpers/dateHelper';

export type PrivateMeetingSessionList = PrivateMeetingSessionDetailsListResponseType;
export type TFilteredMeetings = {
  filteredMeetings: PrivateMeetingSessionList,
};

export interface IGroupedMeetingsList {
  [date: string]: PrivateMeetingSessionList;
}

export interface IProps {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  activeMeetings: PrivateMeetingSessionList;
  meetingsByStartTime: IMeetingsByStartTime[];
  eventToken: string;
  searchedValue: string;
  setFilteredMeetingsCount: (data: IGridFilteredMeetings) => void;
  transformedTimeZone: string;
  isMobile: boolean | void;
}

export interface IGroupedMeetingsListProps {
  event: EventType;
  showAllDates: boolean;
  activeMeetings: PrivateMeetingSessionList;
  meetingsByStartTime: IMeetingsByStartTime[];
  getTileWidth: () => number;
  showPastMeetings: boolean;
  eventToken: string;
  transformedTimeZone: string;
  selectedDate: string;
  isMobile: boolean | void;
}

const GroupedMeetingsList = ({
  meetingsByStartTime,
  showPastMeetings,
  event,
  activeMeetings,
  getTileWidth,
  eventToken,
  transformedTimeZone,
  selectedDate,
  isMobile
}: IGroupedMeetingsListProps): JSX.Element => {
  const [pagination, setPaginationConfig] = useState<IPagination>({
    firstIndex: 0,
    lastIndex: 3,
    step: 1
  });

  const pageContent: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);

  const nowDateInMs = new Date().getTime();
  const className = 'editable-session';
  const classNameSessionTableRow = `${className}--table-row`;
  const classNameSessionTableRowLeft = `${className}--table-row-left`;
  const classNameSessionTableRowRight = `${className}--table-row-right`;
  const classNameSessionTableRowRightTile = `${className}--table-row-right-tile`;
  const classNameSessionTableHeaderLeft = `${className}--table-header-left`;
  const classNameSessionTableHeader = `${className}--table-header`;
  const classNameSessionTableHeaderRight = `${className}--table-header-right`;
  const tileWidth = getTileWidth();

  const getSessionTableRowRightTileClassNames = () => {
    let classnames = classNameSessionTableRowRightTile + ' mx-2 mt-2 flex flex-col';

    if (!isMobile) {
      classnames += ' max-w-40vw w-' + tileWidth + 'px';
    } else {
      classnames += ' w-full';
    }

    return classnames;
  };
  const sessions = activeMeetings.length;
  const isSessionsExist = Boolean(sessions);
  const tracks = getActiveTracks(activeMeetings);
  const trackLength = tracks.length;
  const formattedDate = activeMeetings[0]?.startDateTime;
  const formattedSelectedDate = formattedDate ? getFullDateInUsEuFormat(formattedDate, event.timeZone) : selectedDate;
  const tracksLengthPaginIndex = trackLength > 2 ? 2 : 1;
  const paginationIndex = isMobile ? 0 : tracksLengthPaginIndex;
  const mobileClassNameIndex = isMobile ? 'items-center' : '';
  const meetingsForRender = tracks.filter((_, i) => !(i < pagination.firstIndex || i > pagination.lastIndex + paginationIndex));
  const rowMeetingsAlignment = meetingsForRender.length > 2 ? 'justify-around' : '';

  useLayoutEffect(() => {
    const lastIndex = Math.min(trackLength - pageValues.indexDecrease, pagination.step - pageValues.indexDecrease );
    setPaginationConfig({ firstIndex: pageValues.zeroIndex, step: pagination.step, lastIndex });
  }, [trackLength, setPaginationConfig, pagination.step]);

  const tracksLength = !!tracks.filter(Boolean).length;

  return (
    <>
      <hr />
      { tracksLength &&
        <>
          <div role='rowgroup'>
            <div role='row' className={`${classNameSessionTableHeader} bg-gray-11 flex items-center`} ref={pageContent}>
              <SessionsPagination
                tracks={tracks}
                itemWidth={tileWidth}
                setPaginationConfig={setPaginationConfig}
                trackSectionClassName={classNameSessionTableHeaderRight}
                {...pagination}
                pageContentBlock={pageContent.current}
                paginationIndex={paginationIndex}
              />
            </div>
          </div>
          <hr />
        </>
      }
      <div role='presentation'>
        <div role='columnheader' className={`${classNameSessionTableHeaderLeft} py-8 pl-5 flex items-center`}>
          <span
            className={`${classNameSessionTableHeaderLeft} text-primary font-size-14px font-bold text-left md:text-right uppercase flex justify-start md:justify-end mr-3`}
          >
            {formattedSelectedDate}
          </span>
          {isSessionsExist
            ? (
              <span
                className='font-size-12px whitespace-no-wrap'
              >
                {`${sessions} ${sessions === 1 ? 'session' : 'sessions'}`}
              </span>
            )
            : null
          }
        </div>
        {meetingsByStartTime
          .filter(ms => showPastMeetings || ms.endDateTime.getTime() > nowDateInMs)
          .map((ms, i) => {
            const currentTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const attendeeEndDateTime: Date = utcToZonedTime(ms.endDateTime, currentTimeZone);
            return (
              <div
                role='rowgroup'
                key={i}
                className={`${attendeeEndDateTime < new Date() ?
                  classNameSessionTableRow + ' past-meetings' :
                  classNameSessionTableRow}`
                }
              >
                <div role='rowheader' className={`${classNameSessionTableRowLeft} pr-5 pl-3`}>
                  <GridMeetingTimingBlock
                    transformedTimeZone={transformedTimeZone}
                    meetingDetails={{
                      startDateTime: ms.startTime,
                      endDateTime: ms.endDateTime
                    }}
                    timeZone={event.timeZone}
                    isMultiMeetingsBlock={meetingsByStartTime[i].meetingsList.length > ARRAY_INCREMENT_SIZE}
                    isMobile={isMobile}
                  />
                </div>
                <div role='row' className='flex p-2 bg-primary-lighter-hover min-h-168px'>
                  <div className={`${classNameSessionTableRowRight} w-full flex`}>
                    {activeMeetings
                      .filter(m => m.startDateTime === ms.startTime && m.featured)
                      .map((m, k) => (
                        <CardComponent
                          transformedTimeZone={transformedTimeZone}
                          key={k}
                          meeting={m}
                          event={event}
                          eventToken={eventToken}
                          isGridLayout={true}
                        />
                      ))}
                    <div className={`${rowMeetingsAlignment} ${!tracksLength ? 'flex-wrap' : ''} flex`}>
                      {meetingsForRender.map((t, k) => {
                        return tracksLength
                          ? (
                            <div
                              className={`${getSessionTableRowRightTileClassNames()} ${mobileClassNameIndex}`}
                              role='gridcell'
                              key={k}
                            >
                              {activeMeetings
                                .filter(m => m.startDateTime === ms.startTime && m.track === t && !m.featured)
                                .map((m, k) => (
                                  <div
                                    className={`${getSessionTableRowRightTileClassNames()} ${mobileClassNameIndex}`}
                                    role='gridcell'
                                    key={k}
                                  >
                                    <CardComponent
                                      transformedTimeZone={transformedTimeZone}
                                      key={k}
                                      meeting={m}
                                      event={event}
                                      eventToken={eventToken}
                                      isGridLayout={true}
                                    />
                                  </div>
                                ))
                              }
                            </div>
                          ) :
                          activeMeetings
                            .filter(m => m.startDateTime === ms.startTime && m.track === t && !m.featured)
                            .map((m, k) => (
                              <div
                                className={`${getSessionTableRowRightTileClassNames()} ${mobileClassNameIndex}`}
                                role='gridcell'
                                key={k}
                              >
                                <CardComponent
                                  transformedTimeZone={transformedTimeZone}
                                  key={k}
                                  meeting={m}
                                  event={event}
                                  eventToken={eventToken}
                                  isGridLayout={true}
                                />
                              </div>
                            ))
                        ;
                      })}
                    </div>
                  </div>
                  {!isMobile && <div className='w-50px'>{/* place for meetings pagination controllers */}</div>}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

type IGridLayoutComponent = Omit<IProps, 'activeDates' | ''>

export const GridLayoutComponent: React.FC<IGridLayoutComponent> = (props: IGridLayoutComponent): JSX.Element => {
  const {
    event,
    showAllDates,
    selectedDate,
    showPastMeetings,
    meetingsByStartTime,
    activeMeetings,
    eventToken,
    transformedTimeZone,
    isMobile
  } = props;

  useEffect(() => {
    props.setFilteredMeetingsCount({
      filteredMeetingsLength: activeMeetings.length,
      tracksCount: activeMeetings.reduce((set, meeting) => {
        if (meeting.track) set.add(meeting.track);
        return set;
      }, new Set<string>())
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMeetings.length]);

  return (
    <>
      <div role='grid' aria-label='General Sessions'>
        <GroupedMeetingsList
          event={event}
          showAllDates={showAllDates}
          getTileWidth={getTileWidth}
          activeMeetings={activeMeetings}
          showPastMeetings={showPastMeetings}
          meetingsByStartTime={meetingsByStartTime}
          eventToken={eventToken}
          transformedTimeZone={transformedTimeZone}
          selectedDate={selectedDate}
          isMobile={isMobile}
        />
      </div>
    </>
  );
};

export const Wrapper = (props: IProps): JSX.Element | null => {
  const { meetings, selectedDate, showPastMeetings } = props;
  const { searchedMeetings } = useFilteredCarouselSessions(meetings[selectedDate], props.searchedValue);
  const { activeMeetings, meetingsByStartTime } = useComposeMeeting(searchedMeetings, selectedDate, showPastMeetings);
  const isActiveMeetingsExist = Boolean(activeMeetings.length);
  return isActiveMeetingsExist
    ? (
      <GridLayoutComponent
        {...props}
        activeMeetings={activeMeetings}
        meetingsByStartTime={meetingsByStartTime}
      />
    )
    : null;
};

const GridLayout:React.FC<IProps> = (props: IProps): JSX.Element => {
  const { showAllDates, meetings } = props;
  const filteredMeetingsCount = useRef<IGridFilteredMeetings>({
    filteredMeetingsLength: 0,
    tracksCount: new Set<string>()
  });

  const setFilteredMeetings = (data: IGridFilteredMeetings): void=> {
    filteredMeetingsCount.current.filteredMeetingsLength = filteredMeetingsCount.current.filteredMeetingsLength + data.filteredMeetingsLength;
    const newTracks = new Set([...filteredMeetingsCount.current.tracksCount, ...data.tracksCount]);
    filteredMeetingsCount.current.tracksCount = newTracks;
  };

  useEffect(() => {
    props.setFilteredMeetingsCount(filteredMeetingsCount.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMeetingsCount.current]);

  if (showAllDates) {
    return (
      <>
        { Object.keys(meetings).map((date, i) => (
          <Wrapper
            key={i}
            {...props}
            selectedDate={date}
            setFilteredMeetingsCount={data => setFilteredMeetings(data)}
          />))}
      </>
    );
  }
  return (
    <GridLayoutComponent
      {...props}
      setFilteredMeetingsCount={data => filteredMeetingsCount.current = data}
    />
  );
};

export default GridLayout;
