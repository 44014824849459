import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { COOKIE_NOTIFICATION_EXPIRATION_DAYS, COOKIE_NOTIFICATION_KEY, PassportNonce } from '../../../../lib/constants';
import Button from '../../../../components/button';
import './CookieNotification.scss';
import SafeHTMLRender from '../../SafeHTMLRender';

interface IProps {
  showCookieNotification: boolean;
  cookieNotificationText: string;
}

const CookieNotification: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { showCookieNotification, cookieNotificationText } = props;
  const [showNotification, toggleShowNotification] = useState<boolean | undefined>(showCookieNotification && !Cookies.get(COOKIE_NOTIFICATION_KEY));

  const setCookie = () => {
    Cookies.set(COOKIE_NOTIFICATION_KEY, 'true', { expires: COOKIE_NOTIFICATION_EXPIRATION_DAYS });
    toggleShowNotification(false);
  };

  return (
    <>
      {
        showNotification &&
          <div className={'cookie-notification flex items-center justify-center p-2'}>
            <SafeHTMLRender 
              nonce={PassportNonce.RICH_TEXT} 
              html={cookieNotificationText} 
              className='font-size-14px'
            />
            <Button.Common
              name='cookieNotificationAgree'
              onClick={setCookie}
              label={'Ok'}
              className={'cookie-notification__button flex items-center justify-center rounded bg-primary test-white font-size-14px'}
              ariaLabel={'Ok'}
            />
          </div>
      }
    </>
  );
};

export default CookieNotification;
