import React from 'react';
import { MeetingOrganizationDetailsListType } from '../../../../lib/api';
import './MeetingOrganizationsList.scss';

interface IProps {
  orgs: MeetingOrganizationDetailsListType;
  organizationHeader?: string;
  organizationClassName?: string;
  wrapperClassName?: string;
}

const MeetingOrganizationsList = (props: IProps): JSX.Element | null => {
  const {
    orgs,
    organizationClassName = '',
    wrapperClassName = '',
    organizationHeader = '',
  } = props;

  const isEmptyOrHasNoAttendees = !orgs.length || !orgs.some(org => org.attendees && org.attendees.length > 0);
  if (isEmptyOrHasNoAttendees) return null;

  return (
    <div className={`font-size-12px word-break ${wrapperClassName}`}>
      {organizationHeader && <span className='organization-header'>{organizationHeader}</span>}
      {!!orgs.length && orgs.flatMap(o => o.attendees?.map((a) =>
        <p className={organizationClassName ? organizationClassName : 'py-2'} key={a.attendeeId}>
          <span className='font-semibold'>{`${a.firstName} ${a.lastName}`}</span>
          {a.title && (a.firstName || a.lastName) ? ', ' : ''}{a.title}, {o.name}
        </p>))}
    </div>
  );
};

export { MeetingOrganizationsList };
