import React from 'react';
import SafeHTMLRender from '../../app/shared/SafeHTMLRender';
import { PassportNonce } from '../../lib/constants';

type TextAsHTMLProps = {
  formattedText?: string
}

const TextAsHTML:React.FC<TextAsHTMLProps> = ({ formattedText }) => {
  return <SafeHTMLRender nonce={PassportNonce.RICH_TEXT} html={formattedText || ''}/>;
};

export default TextAsHTML;
