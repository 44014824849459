import * as React from 'react';
import { Redirect } from 'react-router-dom';
import EventDetails from '../../shared/EventDetails';
import isEnabledRoute from '../../../hooks/use-enabled-route';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { ROOT } from '../../../config/routes/paths';
import ReplaysDetails from './ReplaysDetails';
import './ReplaysWrapper.scss';
import { IReplaysContext, ReplaysContextProvider, useReplaysContext } from './ReplaysContextProvider';
import { usePathPrefix } from '../../../hooks/use-path-prefix';
import { normalizeRoutePath } from '../../../lib/helpers/urlHelper';

export const ReplaysContainer: React.FC = () => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;
  const isLoading = auth.isLoading;
  const pathPrefix = usePathPrefix();

  const { eventData, pageConfiguration } = useReplaysContext() as IReplaysContext;

  if (!eventData || isLoading) return null;

  if (isAuthenticated && !eventData?.replaysEnabled) {
    const enabledRoute = isEnabledRoute(eventData?.landingPage);
    return <Redirect to={enabledRoute} />;
  }

  if (!isAuthenticated && !pageConfiguration?.publicEnabled) {
    return <Redirect to={normalizeRoutePath(ROOT, pathPrefix)} />;
  }

  if (eventData) return (
    <>
      <EventDetails details={eventData} />
      <ReplaysDetails label={eventData.replaysLabel || pageConfiguration?.label} />
    </>
  );
  return null;
};

const ReplaysWrapper: React.FC = () => {
  return (
    <ReplaysContextProvider>
      <ReplaysContainer />
    </ReplaysContextProvider>
  );
};

export default ReplaysWrapper;
