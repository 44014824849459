import { AccountContextType, useAccountContext } from '../lib/context-providers/account-context';
import { getFirstPathSegmentFromLocation } from '../lib/helpers/urlHelper';

/**
 * Retrieves the path prefix based on the account context.
 *
 * @returns {string} The first path segment from the location if the account is a subdomain; otherwise, an empty string.
 */
export const usePathPrefix = (): string => {
  const accContext = useAccountContext() as AccountContextType;

  return accContext.isAccountSubdomain ? getFirstPathSegmentFromLocation() : '';
};