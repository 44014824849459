import { unSubscribeCalendarInvite } from '../lib/api/protected';
import { fetchGoogleCalendarLink, fetchOutlookCalendarFile } from '../lib/api/public';
import useDownloadFile from './use-download-file';

interface ResponseType {
  fetchGoogleCalendarForEvent: (eventId: string, redirect?: boolean, eventToken?: string | undefined) => Promise<void>;
  fetchOutlookCalendarForEvent: (eventId: string, fileName: string, eventToken?: string | undefined) => Promise<void>;
  unSubscribeToCalendarEvent: (registrationId: string) => Promise<void>;
}

async function fetchGoogleCalendarForEvent(eventId: string, redirect = false, eventToken: string | undefined): Promise<void> {
  try {
    const url = await fetchGoogleCalendarLink(eventId, eventToken);
    const { link = '' } = url;
    if (redirect) window.location.assign(link);
    else window.open(link);
  } catch (e) {
    throw e;
  }
}

async function fetchOutlookCalendarForEvent(eventId: string, fileName: string, eventToken: string | undefined): Promise<void> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { downloadFile } = useDownloadFile();
  try {
    const fileBlob = await fetchOutlookCalendarFile(eventId, eventToken);
    downloadFile(fileBlob, fileName);
  } catch (e){
    throw e;
  }
}

async function unSubscribeToCalendarEvent(registrationId: string): Promise<void> {
  try {
    await unSubscribeCalendarInvite(registrationId);
  } catch (e) {
    throw e;
  }
}

export default function useEventCalendar(): ResponseType {
  return { fetchGoogleCalendarForEvent, fetchOutlookCalendarForEvent, unSubscribeToCalendarEvent };
}
