import React, { useEffect, useState } from "react";
import { CALENDAR_INVITES, DEFAULT_ERROR_MESSAGE } from "../../lib/constants";
import { useToast } from "../passport/NotificationWrapper/NotificationProvider";
import Icon from "../../components/_base/Icon";
import { CurrentUserResponseType, RegistrationSubscriptionParams } from "../../lib/api";
import Buttons from '../../components/button';
import { preRegisterToCalendarInvite, subscribeToCalendarInvite } from "../../lib/api/protected";
import './SubscribeCalendarButton.scss';
import { useCalendarInviteSubscription } from "../../hooks/use-calendar-subscription";

enum LoadingState {
  None,
  Subscribing,
  Unsubscribing,
}

interface SubscriptionCalendarButtonsProps {
  eventId: string;
  name: string;
  attendeeSubscribed: boolean;
  calendarInvite: CALENDAR_INVITES;
  token: string;
  fileName: string;
  data: CurrentUserResponseType | undefined;
  eventTitleForPassport?: string;
  downloadIcsFile: () => Promise<void>;
  openGoogleCalendar: () => Promise<void>;
  unSubscribeToCalendar: (registrationId: string) => Promise<void>;
  subscribeToEventLabel: string;
  unSubscribeToEventLabel: string;
  className: string;
  isSubscribeToEvent?: boolean;
  updateSubscribe: (subscribed: boolean) => void;
}

const SubscriptionCalendarButtons: React.FC<SubscriptionCalendarButtonsProps> = ({
  eventId,
  name,
  attendeeSubscribed,
  calendarInvite,
  data,
  eventTitleForPassport,
  downloadIcsFile,
  openGoogleCalendar,
  unSubscribeToCalendar,
  subscribeToEventLabel,
  unSubscribeToEventLabel,
  isSubscribeToEvent,
  className,
  updateSubscribe
}) => {
  const { state, subscribe } = useCalendarInviteSubscription();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.None);
  const { addToast } = useToast();

  useEffect(() => {
    if (state.subscribed !== null) {
      handleSubscriptionResult(state.subscribed);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubscriptionResult = async (subscribed: boolean) => {
    if (subscribed) {
      addToast(eventTitleForPassport || name, true);
      await updateSubscribe(true);
    } else {
      await updateSubscribe(false);
    }
    setLoadingState(LoadingState.None);
  };

  const handleSubscription = async (): Promise<void> => {
    if (!data?.email) return;

    setLoadingState(LoadingState.Subscribing);
    try {
      const subscriptionResponse = await preRegisterToCalendarInvite({ attendeeEmail: data.email, eventId: eventId.toString() });
      await updateData(subscriptionResponse, true);
    } catch (error) {
      setLoadingState(LoadingState.None);
      addToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  const updateData = async (data: RegistrationSubscriptionParams, subscribeCalendar: boolean): Promise<void> => {
    try {
      if (data){
        await subscribe({ registrationId: data.registrationId, appSyncToken: data.appSyncToken });
        subscribeCalendar ? await subscribeToCalendarInvite(data.registrationId) : await unSubscribeToCalendar(data.registrationId);
      }
    } catch (error) {
      setLoadingState(LoadingState.None);
      addToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleUnsubscribe = async (): Promise<void> => {
    if (!data?.email) return;

    setLoadingState(LoadingState.Unsubscribing);
    try {
      const result = await preRegisterToCalendarInvite({ attendeeEmail: data.email, eventId: eventId.toString() });
      await updateData(result, false);
    } catch (error) {
      setLoadingState(LoadingState.None);
      addToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <div className="flex justify-between">
      {calendarInvite !== CALENDAR_INVITES.Download &&
        (attendeeSubscribed && loadingState !== LoadingState.Unsubscribing
          ? (
            <Buttons.OptionsButton
              label={unSubscribeToEventLabel}
              name={unSubscribeToEventLabel}
              disabled={false}
              eventId={eventId}
              eventName={name}
              className={`${className} outline-none on-hover-shadow flex items-center pt-px`}
              unSubscribeToCalendar={handleUnsubscribe}
              downloadIcsFile={downloadIcsFile}
              openGoogleCalendar={openGoogleCalendar}
              isSubscribeToEvent={isSubscribeToEvent}
              calendarInvite={calendarInvite}
              isSubscribed
            />
          )
          : loadingState === LoadingState.Subscribing
            ? (
              <Buttons.Common
                name="Subscribing to Event"
                className="connect-to-meeting-btn rounded on-hover-shadow flex mt-5 h-10"
                onClick={handleSubscription}
              >
                <span className="preview-spinner animate-spin" />
                <label className="font-size-11px pt-px">Subscribing to Event...</label>
              </Buttons.Common>
            )
            : loadingState === LoadingState.Unsubscribing
              ? (
                <Buttons.Common
                  name="UnSubscribing to Event"
                  className="connect-to-meeting-btn rounded on-hover-shadow flex mt-5 h-10"
                  onClick={handleSubscription}
                >
                  <span className="preview-spinner animate-spin" />
                  <label className="font-size-11px pt-px">Unsubscribing to Event...</label>
                </Buttons.Common>
              )
              : (
                <Buttons.Common
                  name={subscribeToEventLabel}
                  className="editable-welcome--subscribe-title connect-to-meeting-btn rounded flex mt-5 h-10"
                  onClick={handleSubscription}
                >
                  <Icon.AddToCalendar
                    width="20px"
                    height="20px"
                    viewBox="0 0 50 50"
                    className="fill-primary col order-first mr-2"
                  />
                  <label className="text-primary">{subscribeToEventLabel}</label>
                </Buttons.Common>
              )
        )
      }
      {calendarInvite === CALENDAR_INVITES.Download &&
        <div className="add-to-calendar-btn--wrapper my-6 ml-5">
          <Buttons.OptionsButton
            label="Add to Calendar"
            name="addToCalendar"
            disabled={false}
            eventId={eventId}
            eventName={name}
            className="connect-to-meeting-btn rounded bg-primary text-white on-hover-shadow flex"
            downloadIcsFile={downloadIcsFile}
            openGoogleCalendar={openGoogleCalendar}
            withCalendarIcon
          />
        </div>
      }
    </div>
  );
};

export default SubscriptionCalendarButtons;
