import React from 'react';
import AgendaWrapper from '../app/passport/AgendaWrapper/AgendaWrapper';
import { EmbeddedHTMLCustomPage } from '../app/passport/CustomPages/EmbeddedHTMLCustomPage';
import { EmbeddedUrlCustomPage } from '../app/passport/CustomPages/EmbeddedUrlCustomPage';
import { KnovioCustomPage } from '../app/passport/CustomPages/KnovioCustomPage';
import { RichTextCustomPage } from '../app/passport/CustomPages/RichTextCustomPage';
import SessionWrapper from '../app/passport/SessionWrapper/SessionWrapper';
import ShowcaseWrapper from '../app/passport/ShowcaseWrapper/ShowcaseWrapper';
import SpeakerWrapper from '../app/passport/SpeakerWrapper/SpeakerWrapper';
import SupportPageWrapper from '../app/passport/SupportPageWrapper/SupportPageWrapper';
import WelcomeWrapper from '../app/passport/WelcomeWrapper/WelcomeWrapper';
import _defaultRoutes, { RoutesConfig } from '../config/routes';
import { CustomPageProps, PassportPageType, defaultPageTypes, customPageTypes } from '../lib/api/custom-page.types';
import { ShowcaseCustomPage } from '../app/passport/CustomPages/ShowcaseCustomPage';
import { RedirectPage } from '../app/passport/RedirectPage';
import { IAuthContext, useAuth } from '../lib/context-providers/auth-context';
import { EventSettingsContextType, useEventSettings } from '../lib/context-providers/event-settings-context/event-settings-context';
import { SingleMeetingCustomPage } from '../app/passport/CustomPages/SingleMeetingCustomPage';
import { routesShouldBeInCustomRoutes } from '../config/routes/passport.routes';
import ReplaysWrapper from '../app/passport/ReplaysWrapper/ReplaysWrapper';
import { getFirstPathSegmentFromLocation, normalizeRoutePath } from '../lib/helpers/urlHelper';
import { AccountContextType, useAccountContext } from '../lib/context-providers/account-context';

const CustomPageComponentByType = {
  [PassportPageType.AGENDA]: AgendaWrapper,
  [PassportPageType.SESSIONS]: SessionWrapper,
  [PassportPageType.SHOWCASE]: ShowcaseWrapper,
  [PassportPageType.SPEAKERS]: SpeakerWrapper,
  [PassportPageType.SUPPORT]: SupportPageWrapper,
  [PassportPageType.WELCOME]: WelcomeWrapper,
  [PassportPageType.REPLAYS]: ReplaysWrapper,

  [PassportPageType.KNOVIO]: KnovioCustomPage,
  [PassportPageType.EMBEDDED_WEBPAGE]: EmbeddedUrlCustomPage,
  [PassportPageType.RICH_TEXT]: RichTextCustomPage,
  [PassportPageType.SINGLE_MEETING]: SingleMeetingCustomPage,
  [PassportPageType.EMBEDDED_HTML]: EmbeddedHTMLCustomPage,
  [PassportPageType.USER_CREATED_SHOWCASE]: ShowcaseCustomPage,
  [PassportPageType.EXTERNAL_LINK]: RedirectPage
};


export const isDefaultPage = (page: CustomPageProps): boolean => defaultPageTypes.includes(page.type);

export const isDefaultPageType = (type: PassportPageType): boolean => defaultPageTypes.includes(type);
export const isCustomPageType = (type: PassportPageType): boolean => customPageTypes.includes(type);

export const getPageConfigByType = (customPages: CustomPageProps[], type: PassportPageType): CustomPageProps =>
  customPages.find(page => page.type === type) as CustomPageProps
;

export const extractDefaultPages = (customPages: CustomPageProps[] = []): CustomPageProps[] => customPages.length
  ? customPages.filter(page => defaultPageTypes.includes(page.type))
  : []
;
export const extractCustomPages = (customPages: CustomPageProps[] = []): CustomPageProps[] => customPages.length
  ? customPages.filter(page => customPageTypes.includes(page.type))
  : []
;

export const composeDefaultRoutes = (customPages: CustomPageProps[] = [], pathPrefix?: string): RoutesConfig => {
  if (!customPages.length) return {};
  return Object
    .entries(_defaultRoutes as RoutesConfig)
    .reduce((routes, [path, route]) => {
      const page = getPageConfigByType(customPages, route.type as PassportPageType);
      const _path = normalizeRoutePath(path, pathPrefix);

      routes[_path] = page ? { ...route, ...page } : route;

      return routes;
    }, {} as RoutesConfig);
};

export const composeCustomRoutes = (customPages: CustomPageProps[] = [], isAuthenticated: boolean, pathPrefix?: string): RoutesConfig => {
  if (!customPages.length) return {};
  const additionalRoutes: RoutesConfig = composeDefaultToCustomRoutes(pathPrefix);
  const customPagesRoutes = customPages
    .reduce((routes, route) => {
      const _path = normalizeRoutePath(route.path, pathPrefix);
      routes[_path] = {
        component: () => React.createElement(CustomPageComponentByType[route.type] as React.FC<CustomPageProps>, route),
        _protected: isAuthenticated,
        _public: route.publicEnabled,
        type: route.type,
        label: route.label
      };

      return routes;
    }, {} as RoutesConfig)
  ;

  return {
    ...additionalRoutes,
    ...customPagesRoutes
  };
};

export const useRoutes = (): RoutesConfig | null => {
  const accContext = useAccountContext() as AccountContextType;
  const path = getFirstPathSegmentFromLocation();
  const pathPrefix = accContext.isAccountSubdomain ? path : '';
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = auth?.isAuthenticated || false;

  const data = useEventSettings() as EventSettingsContextType;
  const pages = data?.customPages;

  if (!pages) return null;

  const defaultRoutes = composeDefaultRoutes(extractDefaultPages(pages), pathPrefix);
  const customRoutes = composeCustomRoutes(extractCustomPages(pages), isAuthenticated, pathPrefix);

  return {
    ...defaultRoutes,
    ...customRoutes
  };
};

const composeDefaultToCustomRoutes = (pathPrefix?: string): RoutesConfig => {
  const routes: RoutesConfig = {};

  routesShouldBeInCustomRoutes.forEach(path => {
    const _path = normalizeRoutePath(path, pathPrefix);
    routes[_path] = _defaultRoutes[path];
  });

  return routes;
};
