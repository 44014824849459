import React, { useEffect, useState, useRef, RefObject } from 'react';
import './Select.scss';

export type SelectProps = {
  id: string
  label: string;
  value: string;
  error?: string | boolean;
  span?: string | undefined;
  onChange(value: string): void;
  onBlur?(): void;
  options: string[];
  optionLabels?: {[key: string]: string};
};

export const Select: React.FunctionComponent<SelectProps> = (props: SelectProps): JSX.Element => {
  const {
    onChange,
    options,
    optionLabels,
    onBlur,
    error,
    value,
    label,
    span,
    id,
  } = props;

  const selectClassName = `
    text-sm outline w-full py-2 leading-tight
    input-outline bg-white
    ${error ? ' border-red-600' : ''}
  `;

  const labelClassName = 'block font-bold mt-3 text-sm mb-2';

  const selectRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const selectItem = (item: string) => {
    onChange(item);
    setShowDropDown(false);
  };

  const handleOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (selectRef?.current?.contains(target)) {
      return;
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className='flex'>
        <label className={labelClassName} title={label} htmlFor={id}>
          <span className='mr-1'>{label}</span>
          <span className='mt-3 text-sm mb-2 font-normal'>{span}</span>
        </label>
      </div>
      <div
        ref={selectRef}
        tabIndex={0}
        className={`custom-select ${selectClassName}`}
        onClick={toggleDropDown}
        onBlur={onBlur}
      >
        <div>
          <div>
            <div className='custom-select__selected-item text-ellipsis px-3'>
              {optionLabels?.[value] || value || 'Select an option'}
            </div>
            <div className='custom-select__arrow' />
          </div>
          <div
            className={`custom-select__options outline ${showDropDown ? 'block' : 'hidden'}`}
            tabIndex={0}
          >
            <div
              onClick={() => selectItem('')}
              className={`${value === '' ? 'selected' : ''}`}
              role='listbox'
            >
              <div className='option px-3' role='option' aria-selected={value === ''}>Select an option</div>
            </div>
            {options.map((option, i) => {
              return (
                <div
                  key={i}
                  onClick={() => selectItem(option)}
                  className={`option ${value === option ? 'selected' : ''}`}
                  role='option'
                  aria-selected={value === option}
                >
                  <div className='px-3'>{optionLabels?.[option] || option}</div>
                </div>
              );})}
          </div>
        </div>
      </div>
      {error && (typeof error === 'boolean') && <span className='text-red-600 text-xs py-4'>{error}</span>}
    </>
  );
};

export default Select;
