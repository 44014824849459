import React, { ReactNode } from 'react';
import { IEventCustomSettings } from '../../hooks/use-custom-settings';

import './Footer.scss';

type FooterProps = {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  customSettings: Partial<IEventCustomSettings>;
}

const _className = 'bg-white text-center mt-0 py-4 font-size-14px w-full bottom-0';

const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  /* [QU-6298] NO CHILDREN - NO FOOTER */

  const {
    footerLinks = [],
    showWebsiteFooterLogoImage,
    websiteFooterLogoUrl,
  } = props.customSettings || {};
  const shouldRenderFooter = !!footerLinks.length || !!websiteFooterLogoUrl || showWebsiteFooterLogoImage;

  if (!props.children) return null;
  if (!shouldRenderFooter) return null;

  return (
    <footer className={`${_className} ${props.className || ''}`} style={props.style}>
      {props.children}
    </footer>
  );
};
export default Footer;
