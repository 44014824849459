import useSubdomain from '../../hooks/use-subdomain';

/*eslint-disable*/
export const getEventNameFromUrl = (isDomain = true): string | null => {
  const url = window.location.href;
  return isDomain ? useSubdomain(url) : _getEventNameAsPath(url);
};
/*eslint-enable*/

const _getEventNameAsPath = (url: string): string | null => {
  const match = url.match(/^http:\/\/([^.]+)\.(?:[a-zA-Z].com|localhost:3000)\/([^/]+)\//);

  if (match) {
    const domain = match[1]; // For now we are catching only the domain as the event name
    return domain;
  } else {
    return null;
  }
};

export const normalizeRoutePath = (path: string, pathPrefix?: string): string => {
  let _path = path.startsWith('/') || !path ? path : `/${path}`;
  /* remove trailing slash from the root path when prefix provided */
  if (_path === '/' && pathPrefix) {
    _path = '';
  }

  return pathPrefix ? `/${pathPrefix}${_path}` : _path;
};

export const getFirstPathSegmentFromLocation = (): string => {
  return window.location.pathname.split('/')[1];
};

export const getPathSegmentListFromLocation = (): string[] => {
  return window.location.pathname.split('/').filter(Boolean);
};