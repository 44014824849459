import { AuthenticationMethod } from "../config/api";


class GlobalStore {
  private static instance: GlobalStore | null = null;
  private _authType: AuthenticationMethod | null = AuthenticationMethod.COGNITO;
  private _eventSubdomain: string | null = '';

  public static getInstance(): GlobalStore {
    if (this.instance === null) {
      this.instance = new GlobalStore();
    }
    return this.instance;
  }

  get authenticationMethod(): AuthenticationMethod | null {
    return this._authType;
  }

  set authenticationMethod(value: AuthenticationMethod) {
    this._authType = value;
  }

  get eventSubdomain(): string | null {
    return this._eventSubdomain;
  }

  set eventSubdomain(value: string) {
    this._eventSubdomain = value;
  }

  get isPassportAuthFlow(): boolean {
    return this._authType === AuthenticationMethod.OE_PASSPORT;
  }
}

export default GlobalStore;