import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { getCurrentTimezone, getFullDateInLocalFormat, getTimeInLocalFormat, getFormatTimezone, getFullDateInUsEuFormat } from '../../lib/helpers/dateHelper';

export type DateRangeProps = {
  startTime: string,
  endTime: string,
  timeZone?: string,
  formattedStartTimeClassName?: string,
}

export interface ITimeProps {
  startTime: string;
  endTime: string;
  timeZone: string;
  formattedDateByEventTimeZone?: string;
}

export const TimeRange = (props: ITimeProps): JSX.Element | null => {
  const { startTime, endTime, timeZone } = props;
  if (!startTime && !endTime) return null;
  const currentTimeZone = getCurrentTimezone();
  const startDateTime = utcToZonedTime(startTime, timeZone);
  const formattedDateByEventTimeZone =
    props.formattedDateByEventTimeZone || getFullDateInLocalFormat(startTime, timeZone);
  const formattedDateByEventCurrentTimeZone = getFullDateInLocalFormat(startTime, currentTimeZone);
  const currentOffsetGMT = getFormatTimezone(startDateTime, currentTimeZone);
  const eventOffsetGMT = getFormatTimezone(startDateTime, timeZone);
  const startTimeInEventTimezone = getTimeInLocalFormat(startTime, timeZone);
  const startTimeInCurrentTimezone = getTimeInLocalFormat(startTime, currentTimeZone);
  const endTimeInCurrentTimezone = getTimeInLocalFormat(endTime, currentTimeZone);
  const endTimeInEventTimezone = getTimeInLocalFormat(endTime, timeZone);
  const isTimeZoneAreDifferent = eventOffsetGMT !== currentOffsetGMT;
  const isDatesInTimeZonesAreDifferent = formattedDateByEventTimeZone !== formattedDateByEventCurrentTimeZone;

  return (
    <div className='w-full'>
      <div
        className={`leading-custom-0-7 tracking-custom-1-5 current-timezone-range pt-4 text-black font-size-17px uppercase flex justify-between font-medium`}
      >
        {startTimeInEventTimezone} - {endTimeInEventTimezone}
      </div>
      {
        isDatesInTimeZonesAreDifferent
          ? (
            <p className='event-timezone-range text-left font-size-11px text-black tracking-custom-1-91 leading-custom-0-55'>
              { formattedDateByEventCurrentTimeZone }
            </p>
          )
          : null
      }
      {
        isTimeZoneAreDifferent
          ? (
            <p className='event-timezone-range text-left font-size-11px text-black tracking-custom-1-91 leading-custom-0-55'>
              {`${startTimeInCurrentTimezone} - ${endTimeInCurrentTimezone}  (${currentOffsetGMT})` }
            </p>
          )
          : null
      }
    </div>
  );
};

const DateRange: React.FC<DateRangeProps> = ({
  startTime,
  endTime,
  timeZone,
  formattedStartTimeClassName = ''
}) => {
  //Set current timezone if no timezone passed.
  const formattedTimezone = timeZone || getCurrentTimezone();
  const formattedStartTime = getFullDateInUsEuFormat(startTime, formattedTimezone);
  const formattedEndTime = getFullDateInUsEuFormat(endTime, formattedTimezone);
  const isSameDate = formattedStartTime === formattedEndTime;

  return (<div className={`flex flex-col md:flex-row ${formattedStartTimeClassName}`}>
    <div>{formattedStartTime}</div>
    {!isSameDate && <> <div className='hidden md:block'>&nbsp;-&nbsp;</div><div>{formattedEndTime}</div></>}
  </div>);
};

export default DateRange;
