import React from 'react';
import DateRange from "../../../shared/DateRange";
import TextAsHTML from "../../../../components/_base/TextAsHTML";
import { EventPublicDetailsResponseType } from '../../../../lib/api';
import useSetKeyIdClass from '../../../../hooks/use-set-key-id-class';
import { useTemplateProcessor } from '../../../../hooks/use-template-processor';

interface EventPublicInfoInterface {
  data: EventPublicDetailsResponseType
}

const EventPublicInfo: React.FC<EventPublicInfoInterface> = (props) => {
  const { name, startTime, endTime, publicDescription, timeZone, logoUrl, logoTitle, eventTitleForPassport } = props.data;
  const key = useSetKeyIdClass();
  const renderPublicDescription = useTemplateProcessor();
  const className = key ? `editable-${key}` : 'editable-sign-in';
  const classNameContainer = `${className}--left-content`;
  const classNameTitle = `${className}--left-content-title`;
  const classNameDate = `${className}--left-content-date`;
  const classNameDescription = `${className}--left-content-description`;
  const eventTitle = eventTitleForPassport || name;

  return (
    <div className={`${classNameContainer} ml-6 lg:ml-0 w-full md:w-2/3 pr-12`} id='event-public-info'>
      <div>
        {logoUrl &&
          <img
            src={logoUrl}
            alt={logoTitle}
            title={logoTitle}
            className='object-contain h-32'
          />}
      </div>
      <h2
        className={`${classNameTitle} header-font-size font-bold text-primary line-height-22px header-line word-break mt-5 pre-line`}
        title={eventTitle}
      >
        {eventTitle}
      </h2>
      <div className={`${classNameDate} text-primary font-bold mt-2 uppercase text-sm`}>
        <DateRange startTime={startTime} endTime={endTime} timeZone={timeZone} />
      </div>
      <div className={`${classNameDescription} mt-5 text-sm truncate-advanced-10`}>
        <TextAsHTML formattedText={renderPublicDescription(publicDescription)} />
      </div>
    </div>

  );
};

export default EventPublicInfo;
