import React from 'react';
import TextAsHTML from "../../../components/_base/TextAsHTML";
import './SupportPageInfo.scss';

interface IProps {
  supportInformation: string;
  supportLabel: string;
}
const className = 'editable-support';
const classNameSupportWrapper = `${className}--wrapper`;
const classNameSupportDescription = `${className}--description`;
const classNameSupportTitle = `${className}--title`;

const SupportPageInfo = (props: IProps): JSX.Element => {

  return (
    <div className={`${classNameSupportWrapper} overflow-hidden shadow-gray bg-white mx-auto md:w-3/4 mt-12 max-h-full h-full w-100 mx-0 px-0`}>
      <h1 className={`${classNameSupportTitle} font-size-40px`}>{props?.supportLabel}</h1>
      <hr />
      <div className={`${classNameSupportDescription} truncate-advanced-15`}>
        <TextAsHTML formattedText={props?.supportInformation} />
      </div>
    </div>
  );
};

export default SupportPageInfo;
