import React, { ReactNode } from 'react';
import HeaderNavBar from '../../app/passport/HeaderNavBar';
import { BackgroundImage } from '../../app/shared/BackgroundImage';
import { FooterLinkList } from '../../app/shared/FooterLink';
import PoweredBy from '../../app/shared/PoweredBy';
import { PassportPageType } from '../../lib/api/custom-page.types';
import { CONNECTION_KNOVIO_PATH, NOT_FOUND_PATH, SHOWCASE_PATH } from '../routes/paths';
import { AppConfigurator } from '../../lib/services/app-configuration/AppConfigurator';

const footerLinks = React.createElement(FooterLinkList, { key: 1 });
const poweredBy = React.createElement(PoweredBy, { key: 2 });
const mainClassName = `main-content-wrapper ${footerLinks || poweredBy ? '' : 'no-footer'}`;

export enum RootContainers { Header = 'header', Main = 'main', Footer = 'footer' }
type ConfigItemProps = {
  children?: ReactNode | null,
  className?: string,
  copyright?: boolean
}
export type LayoutConfigType = Record<RootContainers, ConfigItemProps>;
export type RouteLayoutConfig = Record<string, Partial<LayoutConfigType>>
type CustomRouteLayoutConfig = Record<
  'EMBEDDED_WEBPAGE'
  | 'KNOVIO'
  | 'RICH_TEXT'
  | 'EMBEDDED_HTML'
  | 'USER_CREATED_SHOWCASE'
  | 'SINGLE_MEETING'
  , Partial<LayoutConfigType>>;
export type PassportLayoutConfig = LayoutConfigType & {
  routeLayoutConfig: RouteLayoutConfig,
  customRouteLayoutConfig: CustomRouteLayoutConfig
};

const passportLayoutConfig: LayoutConfigType = {
  header: { children: React.createElement(HeaderNavBar), className: 'justify-between' },
  main: {
    className: mainClassName,
    children: React.createElement(BackgroundImage),
  },
  footer: {
    className: 'h-auto',
    copyright: false,
    children: [footerLinks, poweredBy]
  }
};

export const routeLayoutConfig: RouteLayoutConfig = {
  [SHOWCASE_PATH]: {
    main: { className: 'showcase-content-wrapper' },
    footer: { children: null }
  },
  [CONNECTION_KNOVIO_PATH]: {
    main: { className: 'knovio-content-wrapper' },
    footer: { children: null }
  },
  [NOT_FOUND_PATH]: {
    header: { children: null }
  }
};

export const customRouteLayoutConfig: CustomRouteLayoutConfig = {
  [PassportPageType.KNOVIO]: {
    main: { className: 'knovio-content-wrapper' },
    footer: { children: null }
  },
  [PassportPageType.EMBEDDED_WEBPAGE]: {
    main: { className: 'knovio-content-wrapper' },
    footer: { children: null }
  },
  [PassportPageType.RICH_TEXT]: {
    main: {
      className: 'rich-text-content-wrapper',
      children: React.createElement(BackgroundImage),
    },
    footer: { children: null }
  },
  [PassportPageType.SINGLE_MEETING]: {
    main: {
      className: 'main-content-wrapper',
      children: React.createElement(BackgroundImage),
    },
    footer: { children: null }
  },
  [PassportPageType.EMBEDDED_HTML]: {
    main: { className: 'knovio-content-wrapper' },
    footer: { children: null }
  },
  [PassportPageType.USER_CREATED_SHOWCASE]: {
    main: { className: 'showcase-content-wrapper' },
    footer: { children: null }
  },
};

const meetLinksLayoutConfig = {
  main: { className: mainClassName },
  footer: { children: null }
};

const fileDownloadLayoutConfig = {
  main: { className: mainClassName },
  footer: { children: null }
};

export default (() => {
  if (AppConfigurator.isPassportProfile) return { ...passportLayoutConfig, routeLayoutConfig, customRouteLayoutConfig } as PassportLayoutConfig;
  if (AppConfigurator.isMeetLinksProfile) return meetLinksLayoutConfig;
  if (AppConfigurator.isFileDownloadProfile) return fileDownloadLayoutConfig;
  return {};
})();
