import React, { ReactNode, useEffect, useState } from 'react';
import { RoutesConfig } from '../../config/routes';
import useCurrentRoutePath from '../../hooks/use-current-route-path';
import { isCustomPageType, isDefaultPageType } from '../../hooks/use-custom-routes';
import { useCustomPagePathAsCssClass } from '../../hooks/use-set-class';
import { CustomPageProps, PassportPageType } from '../../lib/api/custom-page.types';
import Header, { IHeaderLogoData } from './Header';
import Footer from './Footer';
import { IEventCustomSettings } from '../../hooks/use-custom-settings';
import useSetKeyIdClass, { getCustomPageIdKeyByType } from '../../hooks/use-set-key-id-class';
import useSetId from '../../hooks/use-set-id';
import useLabelFromPath from '../../hooks/use-label-from-path';
import { AppConfigurator } from '../../lib/services/app-configuration/AppConfigurator';
import useSubdomain from '../../hooks/use-subdomain';
import { ERROR_SUBDOMAIN } from '../../lib/constants';
import { useMeetingIdAsCssClass } from '../../hooks/use-set-meeting-id-class';
import { normalizeRoutePath } from '../../lib/helpers/urlHelper';
import { RouteLayoutConfig } from '../../config/layout';

type LayoutItem = 'header' | 'main' | 'footer';
type LayoutItemsProps = Record<LayoutItem, { className?: string, children?: ReactNode | null }>;
type Props = {
  routeLayoutConfig?: Record<string, LayoutItemsProps>,
  customRouteLayoutConfig?: Record<string, LayoutItemsProps>,
  routesConfig: RoutesConfig,
  customPages?: CustomPageProps[],
  customSettings?: IEventCustomSettings,
  routePathPrefix?: string
} & { children?: ReactNode } & Partial<LayoutItemsProps>;

const Layout: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { customSettings = {}, routePathPrefix = '' } = props;
  const [headerLogoData, setHeaderLogoData] = useState<IHeaderLogoData>({
    showWebsiteLogoImage: true,
    websiteLogoUrl: '',
    websiteLogoTitle: '',
    shouldRenderLogo: true,
  });
  const isMeetLink = AppConfigurator.isMeetLinksProfile;
  const isFileDownload = AppConfigurator.isFileDownloadProfile;
  const pathKey = useCurrentRoutePath(routePathPrefix);

  const {
    primaryColor,
    customCss,
    showWebsiteLogoImage,
    websiteLogoTitle,
    websiteLogoUrl,
    pageTitle
  } = customSettings as IEventCustomSettings;
  const key = useSetKeyIdClass();

  useEffect(() => {
    const css = customCss || '';
    const elem = document.querySelector('[data-custom-css="customCss"]');
    if (elem) {
      elem.textContent = css;
    }
  }, [customCss]);

  useEffect(() => {
    setHeaderLogoData({
      showWebsiteLogoImage: isMeetLink || isFileDownload || !!showWebsiteLogoImage,
      websiteLogoUrl: websiteLogoUrl,
      websiteLogoTitle: websiteLogoTitle,
      shouldRenderLogo: isMeetLink || isFileDownload || !!showWebsiteLogoImage || !!websiteLogoUrl,
    });
  }, [websiteLogoUrl, showWebsiteLogoImage, websiteLogoTitle, isMeetLink, isFileDownload]);

  const currentPath = window.location.pathname;
  const routeType = props.routesConfig[currentPath]?.type;
  const routeLabel = useLabelFromPath(currentPath, props.routesConfig, routePathPrefix);
  useCustomPagePathAsCssClass(currentPath, routeType as PassportPageType);
  useMeetingIdAsCssClass(currentPath, props.customPages);

  const idKey = routeType
    ? (isDefaultPageType(routeType) && String(routeType).toLowerCase()) || (isCustomPageType(routeType) && getCustomPageIdKeyByType(routeType)) || ''
    : key
  ;
  useSetId(idKey);

  useEffect(() => {
    document.title = `${routeLabel ? routeLabel + ' |' : ''} ${pageTitle ? pageTitle : ''}`;
  }, [routeLabel, pageTitle]);

  /* normalize route path for use in static layout config. */
  const routeLayoutConfig = Object.entries(props.routeLayoutConfig || {} as object).reduce((cnf, [key, val]) => {
    cnf[normalizeRoutePath(key, routePathPrefix)] = val;
    return cnf;
  }, {} as RouteLayoutConfig);

  /* Layout config for base layout components - header, main content and footer. Can be set per Route path. */
  const headerProps = props.customRouteLayoutConfig?.[routeType as string]?.header ?? routeLayoutConfig?.[pathKey as string]?.header ?? props.header;
  const footerProps = props.customRouteLayoutConfig?.[routeType as string]?.footer ?? routeLayoutConfig?.[pathKey as string]?.footer ?? props.footer;
  const mainProps = props.customRouteLayoutConfig?.[routeType as string]?.main ?? routeLayoutConfig?.[pathKey as string]?.main ?? props.main;
  const customStyle = { '--primary-color': primaryColor } as React.CSSProperties;
  const subdomain = useSubdomain() || '';

  return (
    <>
      {subdomain !== ERROR_SUBDOMAIN && <Header {...headerProps} headerLogoData={headerLogoData} customStyle={customStyle} />}
      <main {...mainProps} style={customStyle}>
        {props.children}
        {mainProps?.children ?? null}
      </main>
      <Footer {...footerProps} style={customStyle} customSettings={customSettings} />
    </>
  );
};

export default Layout;
