import React, { useRef } from 'react';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { EventType, IFilteredMeetings } from '../LayoutWrapper';
import { CarouselWrapperByTrack } from './CarouselWrapper';
import './Card.scss';

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  searchedValue: string;
  setFilteredMeetingsCount: (data: IFilteredMeetings) => void;
  eventToken: string;
  transformedTimeZone: string;
}
const CarouselViewByTrack = (props: ICarouselSection): JSX.Element => {
  const {
    event,
    meetings,
    selectedDate,
    showPastMeetings,
    showAllDates,
    searchedValue,
    setFilteredMeetingsCount,
    eventToken,
    transformedTimeZone,
  } = props;
  const list = showAllDates ? Object.values(meetings).flatMap(m => m) : meetings[selectedDate];
  const filteredMeetingsCount = useRef<IFilteredMeetings>({
    filteredMeetingsLength: 0,
  });
  const handleSetFilteredMeetingsCount = (data: IFilteredMeetings): void => {
    filteredMeetingsCount.current.filteredMeetingsLength = filteredMeetingsCount.current.filteredMeetingsLength + data.filteredMeetingsLength;
    setFilteredMeetingsCount(filteredMeetingsCount.current);
  };

  return (
    <CarouselWrapperByTrack
      event={event}
      meetings={list}
      key={selectedDate}
      selectedDate={selectedDate}
      showAllDates={showAllDates}
      showPastMeetings={showPastMeetings}
      searchedValue={searchedValue}
      setFilteredMeetingsCount={data => handleSetFilteredMeetingsCount(data)}
      eventToken={eventToken}
      transformedTimeZone={transformedTimeZone}
    />
  );
};

export { CarouselViewByTrack };