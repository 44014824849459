import { Auth } from 'aws-amplify';
import API, { PassportAuthTokens, PUBLIC } from '../api';
import GlobalStore from '../../app/global-store';

export async function getCurrentSessionToken(): Promise<string | void> {
  try {
    return GlobalStore.getInstance().isPassportAuthFlow
      ? await getAccessToken()
      : await (await Auth.currentSession())?.getIdToken()?.getJwtToken();
  } catch (e) {
    throw e;
  }
}

export async function validateCurrentAuthenticatedUser(): Promise<void> {
  try {
    return GlobalStore.getInstance().isPassportAuthFlow
      ? await currentAuthenticatedUser()
      : await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (e) {
    throw e;
  }
}

export async function authenticate(username: string, accessCode: string): Promise<void> {
  try {
    return GlobalStore.getInstance().isPassportAuthFlow
      ? await getPassportAuthTokens(username, accessCode)
      : await Auth.signIn(username, accessCode);
  } catch (e) {
    throw e;
  }
}

export async function authenticateWithToken(token: string, username = ''): Promise<void> {
  try {
    return GlobalStore.getInstance().isPassportAuthFlow
      ? await verifyToken(token)
      : await cognitoPasswordlessSignIn(username, token);
  } catch (e) {
    throw e;
  }
}

export async function cognitoPasswordlessSignIn(username: string, token: string): Promise<void> {
  const user = await Auth.signIn(username);
  return await Auth.sendCustomChallengeAnswer(user, token);
}

export async function invalidate(): Promise<void> {
  try {
    return GlobalStore.getInstance().isPassportAuthFlow
      ? removeTokens()
      : await Auth.signOut();
  } catch (e) {
    throw e;
  }
}
export const getPassportAuthTokens = async (username: string, accessCode: string): Promise<void> => {
  const authHeaderValue = btoa(`${username}:${accessCode}`);
  const tokens = await getTokens(authHeaderValue);

  handlePassportAuthTokens(tokens);
};

export const refreshPassportAuthToken = async (): Promise<void> => {
  if (!getRefreshToken()) return;

  const tokens = await refreshAuthToken();

  handlePassportAuthTokens(tokens);
};

const TOKEN_PREFIX = 'PassportIdentity';

export const enum TokenTypes {
  ACCESS = 'access',
  REFRESH = 'refresh',
  ACCESS_TOKEN_EXPIRATION_TIME = 'accessTokenExpirationTime',
}

export const getTokenKey = (eventName: string, tokenType: TokenTypes): string => `${TOKEN_PREFIX}.${eventName}.${tokenType}`;

export const updateRefreshToken = (refreshToken: string): void => {
  if (!refreshToken) return;

  const eventName = GlobalStore.getInstance().eventSubdomain as string;
  const key = getTokenKey(eventName, TokenTypes.REFRESH);
  localStorage.setItem(key, refreshToken);
};

export const updateAccessToken = (accessToken: string): void => {
  if (!accessToken) return;

  const eventName = GlobalStore.getInstance().eventSubdomain as string;
  const key = getTokenKey(eventName, TokenTypes.ACCESS);
  localStorage.setItem(key, accessToken);
};

export const updateAccessTokenLifetime = (tokenLifetime: number): void => {
  const eventName = GlobalStore.getInstance().eventSubdomain as string;
  const key = getTokenKey(eventName, TokenTypes.ACCESS_TOKEN_EXPIRATION_TIME);
  localStorage.setItem(key, tokenLifetime.toString());
};

export const getRefreshToken = (): string => {
  const eventName = GlobalStore.getInstance().eventSubdomain as string;
  const key = getTokenKey(eventName, TokenTypes.REFRESH);
  return localStorage.getItem(key) as string;
};

export const getAccessToken = (): string => {
  const eventName = GlobalStore.getInstance().eventSubdomain as string;
  const key = getTokenKey(eventName, TokenTypes.ACCESS);
  return localStorage.getItem(key) as string;
};

export const getAccessTokenLifetime = (): number => {
  const eventName = GlobalStore.getInstance().eventSubdomain as string;
  const key = getTokenKey(eventName, TokenTypes.ACCESS_TOKEN_EXPIRATION_TIME);
  return Number(localStorage.getItem(key));
};

export const removeTokens = (): void => {
  const tokenKeys = [TokenTypes.ACCESS, TokenTypes.REFRESH, TokenTypes.ACCESS_TOKEN_EXPIRATION_TIME]
    .map(key => getTokenKey(GlobalStore.getInstance().eventSubdomain as string, key));

  tokenKeys.forEach(key => localStorage.removeItem(key));
};

const handlePassportAuthTokens = (tokens: PassportAuthTokens): void => {
  const { accessToken, refreshToken, accessTokenExpirationTime } = tokens;

  updateAccessToken(accessToken);
  updateRefreshToken(refreshToken);
  updateAccessTokenLifetime(accessTokenExpirationTime);
};

function getTokens(authorization: string): Promise<PassportAuthTokens> {
  try {
    const { getAuthToken } = API[PUBLIC];
    return getAuthToken(authorization);
  } catch (e) {
    throw e;
  }
}

function refreshAuthToken(): Promise<PassportAuthTokens> {
  try {
    const { refreshToken } = API[PUBLIC];
    return refreshToken();
  } catch (e) {
    throw e;
  }
}

async function verifyToken(token: string): Promise<void> {
  try {
    const { verifyMagicLinkToken } = API[PUBLIC];
    const tokens = await verifyMagicLinkToken(token);
    handlePassportAuthTokens(tokens);
  } catch (e) {
    removeTokens();
    throw e;
  }
}

async function currentAuthenticatedUser(): Promise<void> {
  const isTokenExpired = getAccessTokenLifetime() && getAccessTokenLifetime() < Date.now();
  const isTokensExist = getAccessToken() && getRefreshToken();

  return new Promise<void>((resolve, reject) => {
    if (!isTokenExpired && isTokensExist) {
      resolve();
    } else if (isTokensExist && isTokenExpired) {
      refreshPassportAuthToken()
        .then(resolve)
        .catch(reject);
    } else {
      reject();
    }
  });
}
