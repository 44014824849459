import React from 'react';
import Button from '../../../../components/button';
import Icon from '../../../../components/_base/Icon';
import './GeneralSessions.scss';
import { MediaQueryContextType, useMediaQuery } from '../../../../lib/context-providers/media-query-provider';
import { ARRAY_INCREMENT_SIZE } from '../../../../lib/constants';

export interface IPagination {
  firstIndex: number,
  lastIndex: number,
  step: number
}
interface IProps extends IPagination {
  setPaginationConfig: (pagination: IPagination) => void;
  pageContentBlock: HTMLDivElement | null;
  itemWidth: number;
  tracks: string[];
  trackSectionClassName: string;
  paginationIndex: number;
}
export const pageValues = {
  indexDecrease: 1,
  zeroIndex: 0,
  minSize: 1,
  maxSize: 6,
  itemPaddingWidth: 16,
  reservedWidth: 220
};

const getTracksClassName = (getTileWidth: number, isMobile: boolean | void): string => {
  const mobileWidth = isMobile ? '' : ' max-w-40vw';
  return 'w-' + getTileWidth + 'px font-size-15px font-semibold pl-2 mx-2 truncate' + mobileWidth;
};

const SessionsPagination: React.FC<IProps> = (props: IProps) => {
  const {
    setPaginationConfig,
    pageContentBlock,
    step,
    itemWidth,
    tracks,
    trackSectionClassName,
    firstIndex,
    lastIndex,
    paginationIndex,
  } = props;

  const trackLength = tracks.length;
  const { isMobile } = useMediaQuery() as MediaQueryContextType;
  const mobileClassNameIndex = isMobile ? 'justify-center' : '';

  const increasePages = () => {
    let firstIndex = Math.min(trackLength - pageValues.indexDecrease, props.lastIndex + step);
    firstIndex = Math.max(pageValues.zeroIndex, firstIndex - step + pageValues.indexDecrease);
    const lastIndex = Math.min(trackLength - pageValues.indexDecrease, props.lastIndex + step);
    setPaginationConfig({ firstIndex, lastIndex, step: step });
  };
  const decreasePages = () => {
    const firstIndex = Math.max(pageValues.zeroIndex, props.firstIndex - step);
    const lastIndex = Math.max(pageValues.zeroIndex, props.firstIndex - step) + step - pageValues.indexDecrease;
    setPaginationConfig({ firstIndex, lastIndex, step: step });
  };
  const shouldRenderArrows = !(!pageContentBlock || !step || trackLength < step + pageValues.indexDecrease + paginationIndex);
  return (
    <div className='general-sessions-arrow-buttons w-full text-center text-primary flex py-2 justify-around pagination-btn'>
      { shouldRenderArrows &&
        <Button.Common
          name='Go to the previous card'
          className={'flex items-center rounded without-common-hover on-hover-shadow outline-auto-visible ' + (props.firstIndex ? 'cursor-pointer' : 'opacity-20 pointer-events')}
          onClick={decreasePages}
          aria-label='Go to the previous card'
        >
          <Icon.ArrowRight
            className='fill-primary'
            title='Previous track button'
            desc='Go to the previous card'
          />
        </Button.Common>
      }
      <div className={`${trackSectionClassName} ${mobileClassNameIndex} w-full flex flex-row py-2`}>
        {tracks
          .slice(firstIndex, lastIndex + ARRAY_INCREMENT_SIZE + paginationIndex)
          .map((t, k) => (
            <span role='columnheader' className={`${getTracksClassName(itemWidth, isMobile)} ${isMobile ? 'pl-5' : ''}`} key={k}>
              {t}
            </span>
          ))}
      </div>
      { shouldRenderArrows &&
        <Button.Common
          name='Go to the next card'
          className={'flex items-center rounded without-common-hover on-hover-shadow outline-auto-visible ml-1 sm:ml-2 ' + ( props.lastIndex + pageValues.indexDecrease + paginationIndex !== trackLength ? 'cursor-pointer' : 'opacity-20 pointer-events')}
          onClick={increasePages}
          aria-label='Go to the next card'
        >
          <Icon.ArrowRight
            className='fill-primary transform rotate-180 transform-origin-7px'
            title='Next track button'
            desc='Go to the next card'
          />
        </Button.Common>
      }
    </div>
  );
};

export default SessionsPagination;
